import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { SERVER_URL } from 'Constants';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import Cookies from 'js-cookie';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import * as React from 'react';
import {Route, Routes} from 'react-router';
import {ToastContainer} from 'react-toastify';
import {store} from './Models/Store';
import Admin from './Views/Admin';
import GlobalModal from './Views/Components/Modal/GlobalModal';
import Frontend from './Views/Frontend';

export default class App extends React.Component {
	constructor(props: any, context: any) {
		super(props, context);

		const httpLink = new HttpLink({ uri: `${SERVER_URL}/api/graphql` });
		
		const authMiddleware = new ApolloLink((operation, forward) => {
			// add the authorization to the headers
			operation.setContext(({ headers = {} }) => ({
				headers: {
					'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
				},
			}));

			return forward(operation);
		})

		store.apolloClient = new ApolloClient({
			cache: new InMemoryCache(),
			link: from([
				authMiddleware,
				httpLink
			]),
		});

		// All state changes should be run in an action so enforce that
		configure({ enforceActions: 'observed' });

		// For cross platform mobile responsiveness
		const defineViewportHeight = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};
		defineViewportHeight();
		window.addEventListener('resize', () => defineViewportHeight());
		
		this.initCornerstone();
	}
	
	public render() {
		return (
			<ApolloProvider client={store.apolloClient}>
				<Provider store={store}>
					<>
						<ToastContainer className="frontend" />
					</>
					<Routes>
						<Route path="/admin/*" element={<Admin />} />
						<Route path="/*" element={<Frontend />} />
					</Routes>
					<GlobalModal ref={ref => ref ? store.modal = ref : undefined} />
				</Provider>
			</ApolloProvider>
		);

	}

	private initCornerstone = () => {
		// IMAGE LOADER
		cornerstoneWADOImageLoader.webWorkerManager.initialize({
			maxWebWorkers: navigator.hardwareConcurrency || 1,
			startWebWorkersOnDemand: true,
			taskConfiguration: {
				decodeTask: {
					loadCodecsOnStartup: true,
					initializeCodecsOnStartup: false,
					codecsPath: 'cornerstoneWADOImageLoaderCodecs.js',
					usePDFJS: false,
				},
			},
		});
	};

}

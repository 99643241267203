import * as React from 'react';
import { observer } from 'mobx-react';

import * as Models from 'Models/Entities';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import { PageWrapper } from 'Views/Components/PageWrapper/PageWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';

@observer
export default class AssetEntityPage extends React.Component {
    public render() {
        const contents = (
            <PageWrapper {...this.props}>
                <EntityCRUD
                    modelType={Models.AssetEntity}
                    selectableItems
                    dateFormat="d/m/Y"
                    altFormat="d/m/Y"
                    {...this.props}
                />
            </PageWrapper>
        );
        
        return (
            <SecuredPage groups={["Super Administrators", "Visitors","SuperAdmin"]}>
                {contents}
            </SecuredPage>
        );
    }
}
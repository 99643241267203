import React, { useEffect, useRef, useState } from 'react';

import { observable, action, toJS, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { store } from 'Models/Store';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { accessLevelOptions, genderTypeOptions, userTypeOptions } from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { Button, Colors, Display, Sizes } from 'Views/Components/Button/Button';
import alert from 'Util/ToastifyUtils';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Link } from 'react-router-dom';
import { SERVER_URL } from 'Constants';
import {log} from "util";

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IUserSiteAccessCrudTileProps {
	orderBy?: IOrderByCondition<Models.UserSiteAccessEntity>;
	perPage?: number;
}

interface ISearch {
	searchTerm: string;
}

const UserSiteAccessCrudTile = (props: IUserSiteAccessCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const collectionRef = useRef<ModelCollection<Models.UserSiteAccessEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.UserSiteAccessEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.UserSiteAccessEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());

	const toggleAccessLevel = (usa: Models.UserSiteAccessEntity) => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want to set the access level to ${usa.accessLevel === 'VIEW_ONLY' ? 'Full Access' : 'View Only'}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							usa.accessLevel = usa.accessLevel === 'VIEW_ONLY' ? 'FULL_ACCESS' : 'VIEW_ONLY';
							usa.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const revokeAccess = (usa: Models.UserSiteAccessEntity) => {
		store.modal.show('Confirmation', 
			<div className="confirmation__modal">
				<h4>{`Do you want to revoke ${usa.user.name}'s access to ${usa.site.siteName}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							usa.delete().then(() => {
								collectionRef.current?.refetch();
								alert('Deleted successfully', 'success');
								store.modal.hide();
							}).catch(errorMessage => {
								alert(
									<div className="delete-error">
										<p className="user-error">This record could not be deleted</p>
										<p className="internal-error-title">Message:</p>
										<p className="internal-error">{errorMessage}</p>
									</div>, 
									'error',
								);
							});
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const toggleHL7Messaging = (usa: Models.UserSiteAccessEntity) => {
		store.modal.show('Confirmation',
			<div className="confirmation__modal">
				<h4>{`Do you want to ${usa.hl7MessagingEnabled ? 'disable' : 'enable'} HL7 Messaging for ${usa.user.name} at site ${usa.site.siteName}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'confirm' }} 
						onClick={() => runInAction(() => {
							usa.hl7MessagingEnabled = !usa.hl7MessagingEnabled;
							usa.save().then(() => {
								alert(`${usa.hl7MessagingEnabled ? 'Enabled' : 'Disabled'} HL7 Messaging`, 'success');
								store.modal.hide();
							}).catch(errorMessage => {
								alert(
									<div className="delete-error">
										<p className="user-error">This record could not be updated</p>
										<p className="internal-error-title">Message:</p>
										<p className="internal-error">{errorMessage}</p>
									</div>, 
									'error',
								);
							});
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const tranformUserName: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => userSiteAccess?.user?.name;
	const tranformSiteName: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => userSiteAccess?.site?.siteName;
	const transformUserType: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => userTypeOptions[userSiteAccess?.user?.userType];
	const transformUserAccess: transformFn<Models.UserSiteAccessEntity> = (userSiteAccess: Models.UserSiteAccessEntity) => {
		if (userSiteAccess.accessLevel === 'VIEW_ONLY') {
			return (
				<Button
					colors={Colors.Secondary}
					display={Display.Solid}
					sizes={Sizes.Small}
					icon={{ icon: 'chevron-down', iconPos: 'icon-right' }}
					onClick={() => runInAction(() => toggleAccessLevel(userSiteAccess))}
				>
					View Only
				</Button>
			);
		}
		return (
			<Button
				colors={Colors.Primary}
				display={Display.Solid}
				sizes={Sizes.Small}
				icon={{ icon: 'chevron-down', iconPos: 'icon-right' }}
				onClick={() => runInAction(() => toggleAccessLevel(userSiteAccess))}
			>
				Full Access
			</Button>
		);
	};

	const getHeaders = (): Array<ICollectionHeaderProps<Models.UserSiteAccessEntity>> => {
		return [
			{
				name: 'name',
				displayName: 'Name',
				sortable: true,
				transformItem: tranformUserName,
			},
			{
				name: 'site',
				displayName: 'Site',
				sortable: true,
				transformItem: tranformSiteName,
			},
			{
				name: 'userType',
				displayName: 'User Type',
				sortable: true,
				transformItem: transformUserType,
			},
			{
				name: 'accessLevel',
				displayName: 'Access Level',
				sortable: true,
				transformItem: transformUserAccess,
			},
		];
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.UserSiteAccessEntity>> = [];

		tableActions.push({
			action: item => {},
			label: 'Revoke Access',
			customButton: (usa: Models.UserSiteAccessEntity) => (
				<Button 
					colors={Colors.Warning}
					display={Display.Solid} 
					sizes={Sizes.Small} 
					buttonProps={{ id: 'revoke-access' }} 
					onClick={() => revokeAccess(usa)}
				>
					Revoke Access
				</Button>
			),
		});

		tableActions.push({
			action: item => {},
			label: 'HL7 Messaging',
			customButton: (usa: Models.UserSiteAccessEntity) => (
				<Button 
					colors={Colors.Primary}
					display={Display.Solid} 
					sizes={Sizes.Small} 
					buttonProps={{ id: `hl7-messaging-${usa.hl7MessagingEnabled ? 'disable' : 'enable'}` }} 
					onClick={() => toggleHL7Messaging(usa)}
				>
					{`${usa.hl7MessagingEnabled ? 'Disable' : 'Enable'} HL7 Messaging`}
				</Button>
			),
		});

		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			tableActions.push({
				action: item => {},
				label: 'Edit',
				customButton: (usa: Models.UserSiteAccessEntity) => (
					<Link to={{ pathname: `${SERVER_URL}/admin/userentity/edit/${usa.userId}` }}>
						<Button 
							colors={Colors.Primary}
							display={Display.Text} 
							sizes={Sizes.Small} 
							buttonProps={{ id: 'edit' }}
							icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
						>
							Edit
						</Button>
					</Link>
				),
			});
		}
		
		return tableActions;
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.UserSiteAccessEntity>>): ApiQueryParams => {
		const params = {};

		const accessFilter = filters.find(f => f.path === 'accessLevel' && !!f.value1);
		if (accessFilter) {
			if ((accessFilter.value1 as string[]).includes('VIEW_ONLY')) {
				params['viewOnly'] = true;
			}
			if ((accessFilter.value1 as string[]).includes('FULL_ACCESS')) {
				params['fullAccess'] = true;
			}
		}

		const userTypeFilter = filters.find(f => f.path === 'userType' && !!f.value1);
		if (userTypeFilter) {
			if ((userTypeFilter.value1 as string[]).includes('CLIENT_DEVICE')) {
				params['clientDevice'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_ADMIN')) {
				params['siteAdmin'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('SITE_USER')) {
				params['siteUser'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('REPORTING_DOCTOR')) {
				params['reportingDoctor'] = true;
			}
			if ((userTypeFilter.value1 as string[]).includes('CARDIAC_SCIENTIST')) {
				params['cardiacScientist'] = true;
			}
		}

		const siteFilter = filters.find(f => f.path === 'site' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteFilter) {
			params['siteFilter'] = JSON.stringify(siteFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.UserSiteAccessEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && !store.userGroups.map(ug => ug.name).includes('Super Administrators')) {
			filterParams['siteIds'] = JSON.stringify(store.userSiteAccess.map(usa => usa.siteId));
		}

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [];

	const getFilters = (): Array<IFilter<Models.UserSiteAccessEntity>> => {
		const accessFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'accessLevel',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Access Level',
			enumResolveFunction: makeEnumFetchFunction(accessLevelOptions),
		};

		const userTypeFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'userType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'User Type',
			enumResolveFunction: makeEnumFetchFunction(userTypeOptions),
		};

		const siteOptions = {};
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			store.sites.forEach(site => {
				if (site.siteName) {
					siteOptions[site.id] = site.siteName;
				}
			});
		} else {
			store.userSiteAccess.forEach(usa => {
				const jsUsa = toJS(usa);
				if (jsUsa.siteId && jsUsa.site) {
					if (jsUsa.site.siteName) {
						siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
					}
				}
			});
		}

		const siteFilter: IFilter<Models.UserSiteAccessEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions)
		};

		return [accessFilter, siteFilter, userTypeFilter];
	};

	return (
		<div>
			<ModelCollection
				ref={collectionRef}
				url="/api/entity/UserSiteAccessEntity/userSiteAccess_list"
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.UserSiteAccessEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={false}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);
};

export default inject('store')(observer(UserSiteAccessCrudTile));

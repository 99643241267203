import * as React from 'react';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import { VisitorsReportTemplateEntity } from 'Models/Security/Acl/VisitorsReportTemplateEntity';
import { SuperAdminReportTemplateEntity } from 'Models/Security/Acl/SuperAdminReportTemplateEntity';
import { UserReportTemplateEntity } from 'Models/Security/Acl/UserReportTemplateEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { FileListPreview } from 'Views/Components/CRUD/Attributes/AttributeFile';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';

export interface IReportTemplateEntityAttributes extends IModelAttributes {
	templateName: string;
	pdfTemplateId: string;
	pdfTemplate: Blob;
	txtTemplateId: string;
	txtTemplate: Blob;
	htmlTemplateId: string;
	htmlTemplate: Blob;
	defaultStressTemplate: boolean;
	defaultEchoTemplate: boolean;
	defaultTransTemplate: boolean;
	defaultCtcaTemplate: boolean;
	defaultCtcsTemplate: boolean;

	siteId?: string;
	site?: Models.SiteEntity | Models.ISiteEntityAttributes;
}

@entity('ReportTemplateEntity', 'Report Template')
export default class ReportTemplateEntity extends Model implements IReportTemplateEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsReportTemplateEntity(),
		new SuperAdminReportTemplateEntity(),
		new UserReportTemplateEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * The name of the template, both PDF and TXT files must have this name
	 */
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute()
	@CRUD({
		name: 'Template Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public templateName: string;

	@observable
	@attribute({ file: 'pdfTemplate' })
	@CRUD({
		name: 'PDF Template',
		displayType: 'hidden',
		order: 20,
		headerColumn: false,
		searchable: false,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'pdfTemplate',
	})
	public pdfTemplateId: string;

	@observable
	public pdfTemplate: Blob;
	
	@observable
	@attribute({ file: 'htmlTemplate' })
	@CRUD({
		name: 'Html Template',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'htmlTemplate',
		displayFunction: attr => (attr
			? <FileListPreview url={attr} />
			: 'No File Attached'),
	})
	public htmlTemplateId: string;

	@observable
	public htmlTemplate: Blob;

	@observable
	@attribute({ file: 'txtTemplate' })
	@CRUD({
		name: 'TXT Template',
		displayType: 'file',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'txtTemplate',
		displayFunction: attr => (attr
			? <FileListPreview url={attr} />
			: 'No File Attached'),
	})
	public txtTemplateId: string;

	@observable
	public txtTemplate: Blob;

	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.SiteEntity,
	})
	public siteId?: string;

	@observable
	@attribute({ isReference: true })
	public site: Models.SiteEntity;

	@observable
	@attribute()
	@CRUD({
		name: 'Default Stress Template',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public defaultStressTemplate: boolean;
	
	@observable
	@attribute()
	@CRUD({
		name: 'Default Echo Template',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public defaultEchoTemplate: boolean;
	
	@observable
	@attribute()
	@CRUD({
		name: 'Default Transoesophageal Template',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public defaultTransTemplate: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'Default CTCA Template',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public defaultCtcaTemplate: boolean;

	@observable
	@attribute()
	@CRUD({
		name: 'Default CTCS Template',
		displayType: 'checkbox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => (attr ? 'True' : 'False'),
	})
	public defaultCtcsTemplate: boolean;

	constructor(attributes?: Partial<IReportTemplateEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IReportTemplateEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.templateName !== undefined) {
				this.templateName = attributes.templateName;
			}
			if (attributes.pdfTemplate !== undefined) {
				this.pdfTemplate = attributes.pdfTemplate;
			}
			if (attributes.pdfTemplateId !== undefined) {
				this.pdfTemplateId = attributes.pdfTemplateId;
			}
			if (attributes.txtTemplate !== undefined) {
				this.txtTemplate = attributes.txtTemplate;
			}
			if (attributes.txtTemplateId !== undefined) {
				this.txtTemplateId = attributes.txtTemplateId;
			}
			if (attributes.htmlTemplate !== undefined) {
				this.htmlTemplate = attributes.htmlTemplate;
			}
			if (attributes.htmlTemplateId !== undefined) {
				this.htmlTemplateId = attributes.htmlTemplateId;
			}
			if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
			if (attributes.site !== undefined) {
				if (attributes.site === null) {
					this.site = attributes.site;
				} else if (attributes.site instanceof Models.SiteEntity) {
					this.site = attributes.site;
					this.siteId = attributes.site.id;
				} else {
					this.site = new Models.SiteEntity(attributes.site);
					this.siteId = this.site.id;
				}
			}
			if (attributes.defaultStressTemplate !== undefined) {
				this.defaultStressTemplate = attributes.defaultStressTemplate;
			}
			if (attributes.defaultEchoTemplate !== undefined) {
				this.defaultEchoTemplate = attributes.defaultEchoTemplate;
			}
			if (attributes.defaultTransTemplate !== undefined) {
				this.defaultTransTemplate = attributes.defaultTransTemplate;
			}
			if (attributes.defaultCtcaTemplate !== undefined) {
				this.defaultCtcaTemplate = attributes.defaultCtcaTemplate;
			}
			if (attributes.defaultCtcsTemplate !== undefined) {
				this.defaultCtcsTemplate = attributes.defaultCtcsTemplate;
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		site {
			${Models.SiteEntity.getAttributes().join('\n')}
			${Models.SiteEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.templateName;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ReportTemplateEntity.prototype, 'created');
CRUD(modifiedAttr)(ReportTemplateEntity.prototype, 'modified');

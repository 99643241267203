import React, { useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import axios, { AxiosResponse } from 'axios';
import { runInAction } from 'mobx';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
	DefaultUnitMap, getSRPathLabel, getVariableLabel, MeasurementsTemplate, PrecisionMap,
} from 'Util/measurements_template';
import Hammer from 'hammerjs';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import ReactResizeDetector from 'react-resize-detector';
import cornerstoneTools from 'cornerstone-tools';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import { SERVER_URL } from '../../../Constants';
import {
	DoctorEntity,
	MeasurementEntity, SiteEntity, StudyEntity, StudyFileEntity,
} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import {withRouter, WithRouterProps}  from "../../../Util/withRouter";
import { ButtonGroup } from '../../Components/Button/ButtonGroup';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import AdvancedReportBuilder from './AdvancedReportBuilder';
import BasicReportBuilder from './BasicReportBuilder';
import MeasurementTile from './MeasurementTile';
import alert from '../../../Util/ToastifyUtils';

const scrollToIndex = cornerstoneTools.import('util/scrollToIndex');

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

interface Option {
	display: string;
	sentence: string;
	conclusion: string;
}

interface LegacyFile {
	studyUid: string;
	archiveId: string;
}

interface Conclusion {
	sentence: string;
	id: string;
}

interface EchoStudyViewerProps extends WithRouterProps {
	study: StudyEntity;
}

const EchoStudyViewer = observer((props: EchoStudyViewerProps) => {
	const { study, navigate } = props;
	const studyType = study.studyType.toLowerCase();

	const refetchStudyMeasurements = async () => {
		await store.apolloClient.query({
			query: StudyEntity.getFetchStudy(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: study.id,
				}],
			},
		}).then(res => {
			const updatedMeasurements = res.data.studyEntity.measurementss;
			study.measurementss.forEach(m => {
				if (m.id === undefined) {
					updatedMeasurements.forEach((um: MeasurementEntity) => {
						if (m.name === um.name && m.value === um.value && m.unit === um.unit) {
							m.id = um.id;
						}
					});
				}
			});
		});
	};

	let heroDicomViewer: HTMLElement | null;

	let gridDicomViewer1: HTMLElement | null;
	let gridDicomViewer2: HTMLElement | null;
	let gridDicomViewer3: HTMLElement | null;
	let gridDicomViewer4: HTMLElement | null;

	let dicomViewerBaseline: HTMLElement | null;
	let dicomViewerPeak: HTMLElement | null;
	let dicomViewerPost: HTMLElement | null;
	let dicomViewerRecovery: HTMLElement | null;

	const cornerstoneState = useLocalStore(() => ({
		tools: [
			{
				name: 'Wwwc',
				mode: 'active',
				modeOptions: { mouseButtonMask: 1 },
			},
			{
				name: 'Zoom',
				mode: 'active',
				modeOptions: { mouseButtonMask: 2 },
			},
			{
				name: 'Pan',
				mode: 'active',
				modeOptions: { mouseButtonMask: 4 },
			},
			// Scroll 
			{ name: 'StackScrollMouseWheel', mode: 'active' },
			// Touch 
			{ name: 'PanMultiTouch', mode: 'active' },
			{ name: 'ZoomTouchPinch', mode: 'active' },
			{ name: 'StackScrollMultiTouch', mode: 'active' },
			// Measure 
			{ name: 'Length', mode: 'enabled' },
			// Area  
			{ name: 'FreehandRoi', mode: 'enabled' },
		],
		imageIds: Array(),
		isLoading: true,
		fullScreen: false,
		heroStack: Array(),
		baseStacksList: Array(),
		peakStacksList: Array(),
		postStacksList: Array(),
		recoverStacksList: Array(),
		studyViewNumber: '1',
		heroId: 0,
		activeTool: '',
		isPlaying: false,
		archived: false,
		restoring: false,
		restoreProgress: false,
		archivedList: Array(),
		restoringList: Array(),
		processedFilesIndex: 0,
		processedESEFilesIndex: 0,
		viewerLoaded: false,
		currentFrameRate: 0,
	}));

	const thumbnailState = useLocalStore(() => ({
		thumbnails: Array(),
	}));

	const legacyArchive = useLocalStore(() => ({
		studyFileIds: Array(),
	}));

	const legacyRestore = useLocalStore(() => ({
		jobIds: Array(),
		restoreActive: false,
		restoreText: 'Checking restore',
	}));

	const viewerState = useLocalStore(() => ({
		view: 'std',
		function: 'play',
	}));

	const updateState = useLocalStore(() => ({
		update: false,
	}));

	// This is what it wants shrug 
	// @ts-ignore 
	// eslint-disable-next-line consistent-return 
	const metaDataProvider = (type: string, imageId: unknown) => {
		const stackData = cornerstoneTools.getToolState(heroDicomViewer, 'stack');

		if (type === 'imagePlaneModule') {
			return {
				pixelSpacing: stackData.data[0].pixelSpacing,
				rowPixelSpacing: stackData.data[0].pixelSpacing,
				columnPixelSpacing: stackData.data[0].pixelSpacing,
			};
		}
	};

	// This is what it wants shrug 
	// @ts-ignore 
	// eslint-disable-next-line consistent-return 
	const metaDataProviderESE = (type: string, imageId: unknown) => {
		const stackData = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');

		if (type === 'imagePlaneModule') {
			return {
				pixelSpacing: stackData.data[0].pixelSpacing,
				rowPixelSpacing: stackData.data[0].pixelSpacing,
				columnPixelSpacing: stackData.data[0].pixelSpacing,
			};
		}
	};

	const startUpViewer = async () => {
		cornerstoneTools.init({ touchEnabled: true });
		// Configure Cornerstone viewer 
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');
		gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
		gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
		gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
		gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

		dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
		dicomViewerPeak = document.getElementById('dicomViewerPeak');
		dicomViewerPost = document.getElementById('dicomViewerPost');
		dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

		// Register custom metaDataProvider with CornerstoneJS 
		cornerstone.enable(heroDicomViewer);

		cornerstone.enable(gridDicomViewer1);
		cornerstone.enable(gridDicomViewer2);
		cornerstone.enable(gridDicomViewer3);
		cornerstone.enable(gridDicomViewer4);

		cornerstone.enable(dicomViewerBaseline);
		cornerstone.enable(dicomViewerPeak);
		cornerstone.enable(dicomViewerPost);
		cornerstone.enable(dicomViewerRecovery);

		await axios.get(`${SERVER_URL}/api/entity/StudyEntity/${study.id}`)
			.then(res => {
				if (res.data !== '') {
					runInAction(() => {
						study.selectedReportTemplate = res.data.selectedReportTemplate;
					});
				}
			}).catch(err => console.log(err));
	};

	const pausePlayButton = () => {
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');

		gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
		gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
		gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
		gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

		dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
		dicomViewerPeak = document.getElementById('dicomViewerPeak');
		dicomViewerPost = document.getElementById('dicomViewerPost');
		dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

		if (cornerstoneState.isPlaying) {
			runInAction(() => {
				cornerstoneState.isPlaying = false;
			});

			if (viewerState.view === 'grid') {
				cornerstoneTools.stopClip(gridDicomViewer1);
				cornerstoneTools.stopClip(gridDicomViewer2);
				cornerstoneTools.stopClip(gridDicomViewer3);
				cornerstoneTools.stopClip(gridDicomViewer4);
			} else if (viewerState.view === 'stress') {
				cornerstoneTools.stopClip(dicomViewerBaseline);
				cornerstoneTools.stopClip(dicomViewerPeak);
				cornerstoneTools.stopClip(dicomViewerPost);
				cornerstoneTools.stopClip(dicomViewerRecovery);
			} else {
				cornerstoneTools.stopClip(heroDicomViewer);
			}
		} else {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			// Need at least one image 
			if (cornerstoneState.heroStack.length > 0) {
				if (viewerState.view === 'grid') {
					cornerstoneTools.playClip(gridDicomViewer1);
					cornerstoneTools.playClip(gridDicomViewer2);
					cornerstoneTools.playClip(gridDicomViewer3);
					cornerstoneTools.playClip(gridDicomViewer4);
				} else if (viewerState.view === 'stress') {
					const stackState1 = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');
					const stackState2 = cornerstoneTools.getToolState(dicomViewerPeak, 'stack');
					const stackState3 = cornerstoneTools.getToolState(dicomViewerPost, 'stack');
					const stackState4 = cornerstoneTools.getToolState(dicomViewerRecovery, 'stack');

					// Must all be in sync with Rest 
					cornerstoneTools.playClip(dicomViewerBaseline, 1 / (stackState1.data[0].effectiveDuration / stackState1.data[0].imageIds.length));

					if (stackState2) {
						cornerstoneTools.playClip(dicomViewerPeak, 1 / (stackState1.data[0].effectiveDuration / stackState2.data[0].imageIds.length));
					}

					if (stackState3) {
						cornerstoneTools.playClip(dicomViewerPost, 1 / (stackState1.data[0].effectiveDuration / stackState3.data[0].imageIds.length));
					}

					if (stackState4) {
						cornerstoneTools.playClip(dicomViewerRecovery, 1 / (stackState1.data[0].effectiveDuration / stackState4.data[0].imageIds.length));
					}

					scrollToIndex(dicomViewerBaseline, 0);
					scrollToIndex(dicomViewerPeak, 0);
					scrollToIndex(dicomViewerPost, 0);
					scrollToIndex(dicomViewerRecovery, 0);
				} else {
					cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
					const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
					const { frameRate } = stackState.data[0];
					cornerstoneTools.playClip(heroDicomViewer, frameRate);
				}
			}
		}
	};

	const slowHeroSpeed = () => {
		heroDicomViewer = document.getElementById('DICOMImageViewerStd');
		cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
		const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');

		if (cornerstoneState.heroStack.length) {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			const { frameRate } = stackState.data[0];

			if (cornerstoneState.currentFrameRate === 0) {
				runInAction(() => {
					cornerstoneState.currentFrameRate = frameRate;
				});
			}

			runInAction(() => {
				cornerstoneState.currentFrameRate *= 0.8;
			});

			cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.currentFrameRate);
		}
	};

	const quickenHeroSpeed = () => {
		if (cornerstoneState.heroStack.length) {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			heroDicomViewer = document.getElementById('DICOMImageViewerStd');
			cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
			const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
			const { frameRate } = stackState.data[0];

			if (cornerstoneState.currentFrameRate === 0) {
				runInAction(() => {
					cornerstoneState.currentFrameRate = frameRate;
				});
			}

			runInAction(() => {
				cornerstoneState.currentFrameRate += (cornerstoneState.currentFrameRate * 0.2);
			});

			cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.currentFrameRate);
		}
	};

	const resetHeroSpeed = () => {
		if (cornerstoneState.heroStack.length) {
			runInAction(() => {
				cornerstoneState.isPlaying = true;
			});

			heroDicomViewer = document.getElementById('DICOMImageViewerStd');
			cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
			const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
			const { frameRate } = stackState.data[0];

			runInAction(() => {
				cornerstoneState.currentFrameRate = frameRate;
			});

			cornerstoneTools.playClip(heroDicomViewer, frameRate);
		}
	};

	const changeStressView = (step: number) => {
		let newView = Number(cornerstoneState.studyViewNumber) + step;

		if (newView > 6) {
			newView = 1;
		} else if (newView < 1) {
			// Reset to last view 
			newView = 6;
		}

		runInAction(() => {
			cornerstoneState.studyViewNumber = newView.toString();
			cornerstoneState.processedESEFilesIndex = 0;
		});

		downloadAndViewESE(newView.toString());
		updateStress(0);
	};

	const onResize = useCallback((viewer: string) => {
		const view = document.getElementById(viewer);

		if (view) {
			cornerstone.resize(view);
		}
	}, []);

	const updateGrid = (currentIndex: number) => {
		gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
		gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
		gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
		gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

		// Stop clip from playing on viewer 
		cornerstoneTools.stopClip(gridDicomViewer1);
		cornerstoneTools.stopClip(gridDicomViewer2);
		cornerstoneTools.stopClip(gridDicomViewer3);
		cornerstoneTools.stopClip(gridDicomViewer4);

		const promises = [];

		if (cornerstoneState.imageIds[currentIndex]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer1, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer1, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer1, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer1, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer1);

				cornerstoneTools.playClip(gridDicomViewer1, cornerstoneState.heroStack[currentIndex].frameRate);
			}));
		}

		if (cornerstoneState.imageIds[currentIndex + 1]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex + 1]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer2, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer2, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer2, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex + 1].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer2, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer2);

				cornerstoneTools.playClip(gridDicomViewer2, cornerstoneState.heroStack[currentIndex + 1].frameRate);
			}));
		}

		if (cornerstoneState.imageIds[currentIndex + 2]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex + 2]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer3, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer3, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer3, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex + 2].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer3, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer3);

				cornerstoneTools.playClip(gridDicomViewer3, cornerstoneState.heroStack[currentIndex + 2].frameRate);
			}));
		}

		if (cornerstoneState.imageIds[currentIndex + 3] && cornerstoneState.heroStack[currentIndex + 3]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.imageIds[currentIndex + 3]).then((image: any) => {
				cornerstoneTools.clearToolState(gridDicomViewer4, 'stack');
				cornerstoneTools.addStackStateManager(gridDicomViewer4, [
					'stack',
					'playClip',
				]);
				cornerstoneTools.addToolState(gridDicomViewer4, 'stack', {
					imageIds: cornerstoneState.heroStack[currentIndex + 3].imageIds,
					currentImageIdIndex: 0,
				});

				// Display the image 
				cornerstone.displayImage(gridDicomViewer4, image);

				// Prefetch the remaining images in the stack (?) 
				cornerstoneTools.stackPrefetch.enable(gridDicomViewer4);

				cornerstoneTools.playClip(gridDicomViewer4, cornerstoneState.heroStack[currentIndex + 3].frameRate);

				runInAction(() => {
					cornerstoneState.isPlaying = true;
				});
			}));
		}

		Promise.all(promises).then(() => {
			onResize('DICOMImageViewerGrid1');
			onResize('DICOMImageViewerGrid2');
			onResize('DICOMImageViewerGrid3');
			onResize('DICOMImageViewerGrid4');
		});
	};

	const updateHero = (index: number) => {
		const x = document.getElementsByClassName('cornerstone-canvas active');

		Array.from(x).forEach(el => {
			el.classList.remove('active');
			el.classList.add('checked');
		});

		// @ts-ignore 
		const thumbnail = document.getElementById('dicom-thumbnails').children[index];

		if (thumbnail) {
			thumbnail.classList.remove('checked');
			thumbnail.className += ' active';
		}

		runInAction(() => {
			cornerstoneState.heroId = index;
		});

		heroDicomViewer = document.getElementById('DICOMImageViewerStd');

		// Stop clip from playing on viewer 
		cornerstoneTools.stopClip(heroDicomViewer);

		if (cornerstoneState.imageIds.length > 0) {
			cornerstone.loadAndCacheImage(cornerstoneState.imageIds[cornerstoneState.heroId]).then((image: any) => {
				// Get the state of the stack tool 
				cornerstoneTools.removeToolState(heroDicomViewer, 'stack');
				const stackState = cornerstoneTools.getToolState(heroDicomViewer, 'stack');
				// Update stack state to reflect selected thumbnail's stack 

				stackState.data[0] = cornerstoneState.heroStack[index];
				stackState.data[0].currentImageIdIndex = 0;

				cornerstoneTools.stackPrefetch.enable(heroDicomViewer);

				// Display the image 
				cornerstone.displayImage(heroDicomViewer, image);
				cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.heroStack[index].frameRate);

				runInAction(() => {
					cornerstoneState.isPlaying = true;
				});
			});

			if (viewerState.view === 'grid') {
				updateGrid(index);
			}
		}
	};

	const updateStress = (index: number) => {
		dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
		dicomViewerPeak = document.getElementById('dicomViewerPeak');
		dicomViewerPost = document.getElementById('dicomViewerPost');
		dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

		// Stop clip from playing on viewer 
		cornerstoneTools.stopClip(dicomViewerBaseline);
		cornerstoneTools.stopClip(dicomViewerPeak);
		cornerstoneTools.stopClip(dicomViewerPost);
		cornerstoneTools.stopClip(dicomViewerRecovery);

		runInAction(() => {
			cornerstoneState.isPlaying = false;
		});

		const promises = [];

		// Have Cornerstone load the Baseline stage images for the selected view 
		if (cornerstoneState.baseStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.baseStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.baseStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerBaseline, image);

				// Register custom metaDataProvider with CornerstoneJS 
				cornerstone.metaData.addProvider(metaDataProviderESE, 1);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerBaseline, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerBaseline, 'stack', cornerstoneState.baseStacksList[index]);
			}));
		}

		// Have Cornerstone load the Peak stage images for the selected view 
		if (cornerstoneState.peakStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.peakStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.peakStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerPeak, image);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerPeak, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerPeak, 'stack', cornerstoneState.peakStacksList[index]);
			}));
		}

		// Have Cornerstone load the Post Peak stage images for the selected view 
		if (cornerstoneState.postStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.postStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.postStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerPost, image);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerPost, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerPost, 'stack', cornerstoneState.postStacksList[index]);
			}));
		}

		// Have Cornerstone load the Recovery stage images for the selected view 
		if (cornerstoneState.recoverStacksList[index]) {
			promises.push(cornerstone.loadAndCacheImage(cornerstoneState.recoverStacksList[index].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.recoverStacksList[index]);

				// Display the rendered image 
				cornerstone.displayImage(dicomViewerRecovery, image);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(dicomViewerRecovery, ['stack', 'playClip']);
				cornerstoneTools.addToolState(dicomViewerRecovery, 'stack', cornerstoneState.recoverStacksList[index]);
			}));
		}

		// Wait until all four views have finished loading (success promises returned) 
		Promise.all(promises).then(() => {
			onResize('dicomViewerBaseline');
			onResize('dicomViewerPeak');
			onResize('dicomViewerPost');
			onResize('dicomViewerRecovery');

			const stackState1 = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');
			const stackState2 = cornerstoneTools.getToolState(dicomViewerPeak, 'stack');
			const stackState3 = cornerstoneTools.getToolState(dicomViewerPost, 'stack');
			const stackState4 = cornerstoneTools.getToolState(dicomViewerRecovery, 'stack');

			// Must all be in sync with Rest 
			cornerstoneTools.playClip(dicomViewerBaseline, 1 / (stackState1.data[0].effectiveDuration / stackState1.data[0].imageIds.length));

			if (stackState2) {
				cornerstoneTools.playClip(dicomViewerPeak, 1 / (stackState1.data[0].effectiveDuration / stackState2.data[0].imageIds.length));
			}

			if (stackState3) {
				cornerstoneTools.playClip(dicomViewerPost, 1 / (stackState1.data[0].effectiveDuration / stackState3.data[0].imageIds.length));
			}

			if (stackState4) {
				cornerstoneTools.playClip(dicomViewerRecovery, 1 / (stackState1.data[0].effectiveDuration / stackState4.data[0].imageIds.length));
			}

			scrollToIndex(dicomViewerBaseline, 0);
			scrollToIndex(dicomViewerPeak, 0);
			scrollToIndex(dicomViewerPost, 0);
			scrollToIndex(dicomViewerRecovery, 0);

			runInAction(() => cornerstoneState.isPlaying = true);
		});
	};

	const changeViewerState = (newViewerState: string): void => {
		if (viewerState.view !== newViewerState) {
			runInAction(() => {
				viewerState.view = newViewerState;
			});

			if (newViewerState === 'grid') {
				updateGrid(cornerstoneState.heroId);
			} else if (newViewerState === 'stress') {
				updateStress(0);
			} else {
				updateHero(cornerstoneState.heroId);
			}
		}
	};

	const restoreFiles = (): void => {
		cornerstoneState.archivedList.forEach(studyId => {
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/restore/${studyId}`)
				.catch(err => console.log(err));
		});

		legacyArchive.studyFileIds.forEach((file: LegacyFile) => {
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/restoreOldArchive/${file.studyUid}/${file.archiveId}`);
		});

		alert('StudyFiles are being restored', 'success');
		store.modal.hide();
	};

	const generateThumbnail = (index: number) => {
		// Get JPEG from server 
		const jpg = thumbnailState.thumbnails[index];

		const thumbnailsViewer = document.getElementById('dicom-thumbnails');
		const thumbnail = document.createElement('img');
		thumbnail.setAttribute('src', `data:image/png;base64,${jpg}`);
		thumbnail.className = 'cornerstone-canvas';
		if (cornerstoneState.heroId === index) {
			thumbnail.className += ' active';
		}
		thumbnail.addEventListener('click', () => updateHero(index));
		thumbnail.addEventListener('touchstart', () => updateHero(index));

		if (thumbnailsViewer) {
			thumbnailsViewer.appendChild(thumbnail);
		}

		cornerstone.loadAndCacheImage(cornerstoneState.heroStack[index].imageIds[0]);

		if (!cornerstoneState.viewerLoaded) {
			cornerstone.loadAndCacheImage(cornerstoneState.heroStack[0].imageIds[0]).then((image: any) => {
				// Free memory for loaded DICOM file 
				cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(cornerstoneState.heroStack[0]);

				cornerstone.displayImage(heroDicomViewer, image);
				cornerstone.displayImage(gridDicomViewer1, image);
				cornerstone.displayImage(gridDicomViewer2, image);
				cornerstone.displayImage(gridDicomViewer3, image);
				cornerstone.displayImage(gridDicomViewer4, image);

				const {
					LengthTool, WwwcTool, FreehandRoiTool, ZoomTool, PanTool,
				} = cornerstoneTools;

				cornerstoneTools.addTool(LengthTool);
				cornerstoneTools.addTool(FreehandRoiTool);
				cornerstoneTools.addTool(WwwcTool);
				cornerstoneTools.addTool(ZoomTool);
				cornerstoneTools.addTool(PanTool);

				cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
				cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 });

				// Register custom metaDataProvider with CornerstoneJS 
				cornerstone.metaData.addProvider(metaDataProvider, 1);

				// Set the stack as tool state 
				cornerstoneTools.addStackStateManager(heroDicomViewer, ['stack', 'playClip']);
				cornerstoneTools.addToolState(heroDicomViewer, 'stack', cornerstoneState.heroStack[0]);

				cornerstoneTools.playClip(heroDicomViewer, cornerstoneState.heroStack[0].frameRate);
			});

			runInAction(() => {
				cornerstoneState.isPlaying = true;
				cornerstoneState.viewerLoaded = true;
			});
		}
	};

	const loadAndViewImageESE = (selectedView: string, url: string) => {
		// clear out stacks 
		// Load specified DICOM file from Web Server and store in memory cache 
		cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
			.load(url.replace('wadouri:', ''), cornerstoneWADOImageLoader.internal.xhrRequest)
			.then((dataSet: any) => {
				// Dataset is now loaded, get relevant metadata for the DICOM file 
				const numFrames = dataSet.intString('x00280008');
				const frameRate = dataSet.intString('x00082144');
				let deltaX = 0.0;

				// Try and get Sequence of Ultrasound Regions (0018,6011) attribute 
				const ultrasoundRegions = dataSet.elements.x00186011;
				if (ultrasoundRegions !== undefined && ultrasoundRegions.items.length > 0) {
					deltaX = ultrasoundRegions.items[0].dataSet.double('x0018602c') * 10.0;
				}

				// Try to get ESE specific metadata from DICOM file 
				const viewNumber = dataSet.intString('x00082128');
				const stageNumber = dataSet.intString('x00082122');
				const effectiveDuration = dataSet.floatString('x00180072');

				// Ignore the ESLint issue to change this from a double == to triple, it needs to be double 
				/* 
                    This is becuase the viewNumber is of type any, and the selectedView is of type string, 
                    I think that there was just a mismatch in the equality checking. 
                 */
				// eslint-disable-next-line eqeqeq 
				if (viewNumber !== undefined && stageNumber !== undefined && viewNumber == selectedView) {
					// Define base URL in correct format 
					const imageIdRoot = `${SERVER_URL}${url}`;
					// Check which stage this ESE view belongs to 
					if (stageNumber === 1) {
						// Create a stack object for the specified DICOM file 
						const baseStack = {
							stackIndex: cornerstoneState.baseStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							baseStack.imageIds.push(imageId);
						}

						// Add stack to Baseline stack list 
						runInAction(() => {
							cornerstoneState.baseStacksList[0] = baseStack;
						});
					} else if (stageNumber === 2) {
						// Create a stack object for the specified DICOM file 
						const peakStack = {
							stackIndex: cornerstoneState.peakStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							peakStack.imageIds.push(imageId);
						}

						// Add stack to Peak stack list 
						runInAction(() => {
							cornerstoneState.peakStacksList[0] = peakStack;
						});
					} else if (stageNumber === 3) {
						// Create a stack object for the specified DICOM file 
						const postStack = {
							stackIndex: cornerstoneState.postStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							postStack.imageIds.push(imageId);
						}

						// Add stack to Post Peak stack list 
						runInAction(() => {
							cornerstoneState.postStacksList[0] = postStack;
						});
					} else if (stageNumber === 4) {
						// Create a stack object for the specified DICOM file 
						const recoverStack = {
							stackIndex: cornerstoneState.recoverStacksList.length,
							currentImageIdIndex: 0,
							imageIds: Array(),
							frameRate,
							effectiveDuration,
							pixelSpacing: deltaX,
							viewNumber,
							stageNumber,
						};

						// Populate imageIds array with URLs for each frame in DICOM file 
						for (let i = 0; i < numFrames; i++) {
							const imageId = `${imageIdRoot}?frame=${i}`;
							recoverStack.imageIds.push(imageId);
						}

						// Add stack to Recovery stack list 
						runInAction(() => {
							cornerstoneState.recoverStacksList[0] = recoverStack;
						});
					}
				} else {
					// Skip selected DICOM file and free associated memory 
					cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(url);
				}

				runInAction(() => {
					cornerstoneState.processedESEFilesIndex += 1;
				});

				// While there are still more DICOM files to process 
				if (cornerstoneState.processedESEFilesIndex < cornerstoneState.imageIds.length) {
					// Load next DICOM file 
					loadAndViewImageESE(selectedView, cornerstoneState.imageIds[cornerstoneState.processedESEFilesIndex]);
				}
			});
	};

	const loadAndViewImage = (url: string) => {
		// Load specified DICOM file from Web Server and store in memory cache 
		cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
			.load(url.replace('wadouri:', ''), cornerstoneWADOImageLoader.internal.xhrRequest).then((dataSet: any) => {
				// Dataset is now loaded, get relevant metadata for the DICOM file 
				const numFrames = dataSet.intString('x00280008');
				const frameRate = dataSet.intString('x00082144');
				let deltaX = 0.0;

				// Try and get Sequence of Ultrasound Regions (0018,6011) attribute 
				const ultrasoundRegions = dataSet.elements.x00186011;
				if (ultrasoundRegions !== undefined && ultrasoundRegions.items.length > 0) {
					deltaX = ultrasoundRegions.items[0].dataSet.double('x0018602c') * 10.0;
				}

				// Create a stack object for the specified DICOM file 
				const stack = {
					stackIndex: cornerstoneState.processedFilesIndex,
					currentImageIdIndex: 0,
					imageIds: Array(),
					frameRate,
					pixelSpacing: deltaX,
				};

				// Define base URL in correct format 
				const imageIdRoot = `${SERVER_URL}${url}`;

				// Determine if the DICOM file contains multiple frames or is a still image 
				if (numFrames !== undefined) {
					// Populate imageIds array with URLs for each frame in DICOM file 
					for (let i = 0; i < numFrames; i++) {
						const imageId = `${imageIdRoot}?frame=${i}`;
						stack.imageIds.push(imageId);
					}
				} else {
					// Add the single image URL 
					runInAction(() => {
						stack.imageIds.push(imageIdRoot);
					});
				}

				// Add the stack of the global stacks list 
				runInAction(() => {
					cornerstoneState.heroStack.push(stack);
				});

				// Generate thumbnail for recently processed DICOM file 
				generateThumbnail(cornerstoneState.processedFilesIndex);

				// Increment processed files index 
				runInAction(() => {
					cornerstoneState.processedFilesIndex += 1;
				});

				// While there are still more DICOM files to process 
				if (cornerstoneState.processedFilesIndex < cornerstoneState.imageIds.length) {
					// Load next DICOM file 
					loadAndViewImage(cornerstoneState.imageIds[cornerstoneState.processedFilesIndex]);
				}
			});
	};

	const downloadAndViewESE = (viewNumber: string) => {
		if (cornerstoneState.imageIds.length) {
			loadAndViewImageESE(viewNumber, cornerstoneState.imageIds[cornerstoneState.processedESEFilesIndex]);
		}
	};

	const downloadAndView = () => {
		if (cornerstoneState.imageIds.length) {
			// Loop through each DICOM file for this study (start with first file initially) 
			loadAndViewImage(cornerstoneState.imageIds[cornerstoneState.processedFilesIndex]);
		}
	};

	const siteState = useLocalStore(() => ({
		site: new SiteEntity(),
		siteFetched: false,
	}));

	const languageState = useLocalStore(() => ({
		namespace: 'default',
	}));

	const reportState = useLocalStore(() => ({
		builder: 'basic',
	}));

	const pdfs = useLocalStore(() => ({
		stressEcg: '',
		referral: '',
		twelveLead: '',
		preview: ''
	}));

	const loadingState = useLocalStore(() => ({
		loading: true,
	}));

	const { t, i18n } = useTranslation();

	const measurements = useLocalStore(() => ({ ...cloneDeep(MeasurementsTemplate) }));

	const translationState = useLocalStore(() => ({
		translations: {} as any,
		prepopulationConditions: {} as any,
		measurementLabelMap: {} as any,
		sentenceTemplates: {} as any,
	}));

	const reportMeasurementsState = useLocalStore(() => ({
		reportMeasurementsObject: study.reportMeasurements !== undefined && study.reportMeasurements !== null ? JSON.parse(study.reportMeasurements) : {} as any,
	}));

	const updateReportMeasurementsState = (newState: any) => {
		runInAction(() => {
			reportMeasurementsState.reportMeasurementsObject = newState;
		});
	};

	const calculatedMeasurementsState = useLocalStore(() => ({
		MMLVIDDIndex: new MeasurementEntity({ name: '(MM)LVIDDIndex' }),
		$2DLVIDDIndex: new MeasurementEntity({ name: '(2D)LVIDDIndex' }),
		$2DLVEDVMODBPIndex: new MeasurementEntity({ name: '(2D)LVEDVMODBPIndex' }),
		$2DAOROOTIndex: new MeasurementEntity({ name: '(2D)AOROOTIndex' }),
		$2DASCAOIndex: new MeasurementEntity({ name: '(2D)ASCAOIndex' }),
		$2DLAEDVMODBPIndex: new MeasurementEntity({ name: '(2D)LAEDVMODBPIndex' }),
		$2DLAEDVMODALIndex: new MeasurementEntity({ name: '(2D)LAEDVMODALIndex' }),
		CAVAVTIIndex: new MeasurementEntity({ name: 'C-AVAVTIIndex' }),
		CAVDI: new MeasurementEntity({ name: 'C-AVDI' }),
		PWMVADURPWPVEINARDUR: new MeasurementEntity({ name: 'PWMVADURPWPVEINARDUR' }),
		CWPAEDP: new MeasurementEntity({ name: 'CWPAEDP' }),
		CWTRPGRAD: new MeasurementEntity({ name: 'CWTRPGRAD' }),
	}));

	const observationState = useLocalStore(() => ({
		demographic: {
			clinicalDetails: '',
			height: '',
			weight: '',
			BSA: '',
			SBP: '',
			DBP: '',
			HR: '',
			referringDoctorPrefix: '',
			referringDoctorLastName: '',
			referringDoctorFirstName: '',
			sonographer: '',
			reportingDoctor: '',
			recommendations: '',
			ccRecipients: '',
		},
	}));
	const basicReportState = useLocalStore(() => ({
		reportData: study.report?.reportData ? study.report?.reportData : '',
		advancedReportString: study.report?.advancedreportstring ? study.report?.advancedreportstring : '',
	}));

	const conclusionState = useLocalStore(() => ({
		conclusions: [] as Conclusion[],
	}));

	const removeConclusion = (conclusion: Conclusion) => {
		runInAction(() => {
			conclusionState.conclusions = [...conclusionState.conclusions.filter(c => c.id !== conclusion.id)];
		});
	};

	const handleConclusionSelect = (section: string, element: string): void => {
		runInAction(() => {
			observationState[section][element].conclusion = !observationState[section][element].conclusion;
			if (observationState[section][element].conclusion) {
				let conclusionSentence = translationState.translations[section][element]
					.filter((elementOption: Option) => elementOption.display === observationState[section][element].value)[0].conclusion
					.replace(/m2/g, 'm²').replace('[', '').replace(']', '');

				Object.keys(translationState.measurementLabelMap).forEach(key => {
					const varLabel = getSRPathLabel(key);

					const reportLabel = translationState.measurementLabelMap[key];
					if (conclusionSentence.includes(varLabel)) {
						conclusionSentence = conclusionSentence.replace(varLabel, reportLabel);

						const hasMeasurement = conclusionSentence.match(`${reportLabel}\\s?\\d+`);
						if (hasMeasurement === null) {
							conclusionSentence = conclusionSentence
								.replace(reportLabel, '')
								.replace('(  , ', '')
								.replace(',  ,', ',')
								.replace(',  .', '.')
								.replace(',  )', ')');
						}
					}
				});

				conclusionState.conclusions.push(
					{
						sentence: conclusionSentence,
						id: `${section}-${element}`,
					},
				);
			} else {
				conclusionState.conclusions = [...conclusionState.conclusions.filter(conclusion => conclusion.id !== `${section}-${element}`)];
			}
		});
	};

	const doctorsState = useLocalStore(() => ({
		reportingDoctors: [] as DoctorEntity[],
		sonographers: [] as string[],
	}));

	const fetchDoctors = async () => {
		await axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetSonographers/${study.patient.siteId}`)
			.then(res => {
				if (res.data !== '') {
					runInAction(() => {
						doctorsState.sonographers = res.data;
					});
				}
			}).catch(err => console.log(err));
		await axios.get(`${SERVER_URL}/api/entity/SiteEntity/GetReportingDoctors/${study.patient.siteId}`)
			.then(res => {
				if (res.data !== '') {
					runInAction(() => {
						doctorsState.reportingDoctors = res.data;
					});
				}
			}).catch(err => console.log(err));
	};
	
	const checkRestore = (): void => {
		// Need all to finish before using 
		runInAction(() => {
			cornerstoneState.restoreProgress = true;
		});

		const promises: any[] = [];

		if (legacyRestore.jobIds.length) {
			runInAction(() => {
				legacyRestore.restoreActive = true;
			});

			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/checkOldRestore/${legacyRestore.jobIds[0].id}`)
				.then(res => {
					if (!res.data) {
						alert('Restore is still in progress', 'warning');
					} else {
						alert('Study Files have been restored, refresh to load files.', 'success');
						store.modal.hide();
					}
				}).finally(() => {
					runInAction(() => {
						legacyRestore.restoreActive = false;
					});
				});
		} else {
			cornerstoneState.restoringList.forEach(studyFileId => {
				promises.push(axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/checkRestore/${studyFileId}`)
					.catch(err => console.log(err)));
			});

			axios.all(promises)
				.then(axios.spread((...args) => {
					for (let i = 0; i < args.length; i++) {
						if (!args[i].data) {
							runInAction(() => {
								let imageIdCopy: string[] = cornerstoneState.imageIds;
								imageIdCopy = imageIdCopy.filter(e => e);
								imageIdCopy.push(`wadouri:${SERVER_URL}/api/files/${cornerstoneState.restoringList[i]}`);

								cornerstoneState.imageIds = imageIdCopy;

								// Remove from restoring list because successful 
								cornerstoneState.restoringList.splice(i, 1);
							});
						}
					}
				})).then(() => {
					if (cornerstoneState.restoringList.length) {
						alert(`There is still ${cornerstoneState.restoringList.length} files being restored`, 'warning');
					} else {
						alert('All files have been restored', 'success');
						store.modal.hide();
					}

					runInAction(() => {
						cornerstoneState.restoreProgress = false;
					});
				});
		}
	};

	const toggleArchivedModal = (): void => {
		store.setArchiveModal(cornerstoneState.archived);

		if (store.archiveModal) {
			store.modal.show('Archived', (
				<>
					<h4>Archived</h4>
					<div>
						At least one of this studies files has been archived. Do you want to restore?
					</div>
					{!cornerstoneState.isLoading && (
						<ButtonGroup>
							<Button
								colors={Colors.Primary}
								display={Display.Solid}
								sizes={Sizes.Medium}
								onClick={() => restoreFiles()}
							>
								Yes
							</Button>
							<Button
								colors={Colors.Primary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								onClick={() => store.modal.hide()}
							>
								Close
							</Button>
						</ButtonGroup>
					)}
				</>
			));
		} else {
			store.modal.hide();
		}
	};

	const toggleRestoreModal = (): void => {
		store.setArchiveModal(cornerstoneState.restoring);

		if (store.archiveModal) {
			store.modal.show('Restoring',
				<>
					<h4>Restoring</h4>
					<div>
						There are study files being restored on this study. Do you want to check status?
					</div>
					{!cornerstoneState.isLoading && (
						<ButtonGroup>
							{!cornerstoneState.restoreProgress && (
								<Button
									colors={Colors.Primary}
									display={Display.Solid}
									sizes={Sizes.Medium}
									disabled={legacyRestore.restoreActive}
									onClick={() => checkRestore()}
								>
									{legacyRestore.restoreActive ? legacyRestore.restoreText : 'Yes'}
								</Button>
							)}

							<Button
								colors={Colors.Primary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								onClick={() => store.modal.hide()}
							>
								Close
							</Button>
						</ButtonGroup>
					)}
				</>);
		} else {
			store.modal.hide();
		}
	};

	const getAllDicoms = (): void => {
		// Get all the associated DICOMs 
		const fetchDicomPromise = [];

		// Get all available 
		fetchDicomPromise.push(
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyDicomFileByStudy/${study.id}`)
				.then(res => {
					res.data.forEach((obj: StudyFileEntity) => {
						runInAction(() => {
							cornerstoneState.imageIds.push(`wadouri:${SERVER_URL}/api/files/${obj.studyFileId}`);
							thumbnailState.thumbnails.push(obj.thumbnail);
						});
					});
				}).finally(() => {
					runInAction(() => {
						cornerstoneState.isLoading = false;
					});
				}).catch(err => console.error(err)),
		);

		fetchDicomPromise.push(
			// Get all restoring 
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/RestoringStudyDicomFileByStudy/${study.id}`)
				.then(res => {
					res.data.forEach((file: any) => {
						runInAction(() => {
							// Legacy Restore 
							if (file.archiveId) {
								legacyRestore.jobIds.push(file);
								cornerstoneState.restoring = true;
							} else { // Normal restore 
								cornerstoneState.restoringList.push(file.studyFileId);
								cornerstoneState.restoring = true;
							}
						});
					});
				}).catch(err => console.log(err)),
		);

		fetchDicomPromise.push(
			// Get all archived 
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/ArchivedStudyDicomFileByStudy/${study.id}`)
				.then(res => {
					res.data.forEach((file: any) => {
						if (file.archiveId) {
							const legacyFile: LegacyFile = {
								studyUid: file.studyId ? file.studyId : '',
								archiveId: file.archiveId,
							};

							legacyArchive.studyFileIds.push(legacyFile);
						} else {
							runInAction(() => {
								cornerstoneState.archivedList.push(file.studyFileId);
							});
						}

						runInAction(() => {
							cornerstoneState.archived = true;
						});
					});
				}).catch(err => console.log(err)),
		);

		Promise.all(fetchDicomPromise).then(() => {
			// Start downloading and viewing study images 
			downloadAndView();
			// Get all ESE 
			downloadAndViewESE(cornerstoneState.studyViewNumber);

			if (cornerstoneState.archived) {
				toggleArchivedModal();
			} else if (cornerstoneState.restoring) {
				toggleRestoreModal();
			}
		});

		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyStressReferralFileByStudy/${study.id}`)
			.then(res => {
				res.data.forEach((file: StudyFileEntity) => {
					runInAction(() => {
						pdfs.referral = `${SERVER_URL}/api/files/${file.studyFileId}`;
					});
				});
			})
			.catch(err => console.log(err));

		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/TwelveLeadFileByStudy/${study.id}`)
			.then(res => {
				res.data.forEach((file: StudyFileEntity) => {
					runInAction(() => {
						pdfs.twelveLead = `${SERVER_URL}/api/files/${file.studyFileId}`;
					});
				});
			})
			.catch(err => console.log(err));
		

		if (study.report?.advancedreportstring) {
			fetchPreview();
		}

		if (study.studyType === 'STRESS_ECHO') {
			// Get Stress ECG file 
			axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyStressECGFileByStudy/${study.id}`)
				.then(res => {
					res.data.forEach((file: StudyFileEntity) => {
						if (file.fileType === 'STRESS_ECG_REPORT') {
							runInAction(() => {
								pdfs.stressEcg = `${SERVER_URL}/api/files/${file.studyFileId}`;
							});
						}
					});
				});
		}
	};

	// Function that will clear all of the fields on the page 
	const clearPage = (): void => {
		runInAction(() => {
			Object.keys(translationState.translations).forEach(section => {
				Object.keys(translationState.translations[section]).forEach(element => {
					observationState[section][element].output = '';
					observationState[section][element].value = '';
					if (observationState[section][element].conclusion === true) {
						observationState[section][element].conclusion = false;
						conclusionState.conclusions = [...conclusionState.conclusions.filter(conclusion => conclusion.id !== `${section}-${element}`)];
					}
				});
				observationState[section].sentence = Object.keys(observationState[section]).map(ele => observationState[section][ele].output).filter(x => x !== undefined && x !== '').join(' ');
			});
		});
	};

	/* 
    Function that handles automatically populating fields based on the sentence templates 
    in the translation.json file. The clear function works by setting all fields to N/A 
    */
	const handleSentenceTemplateSelect = (severity: string): void => {
		runInAction(() => {
			if (severity === 'undefined') return;
			if (severity === 'clear') {
				clearPage();
			} else {
				// Try catch is used here to check if that section of the template is undefined, if it is just return 
				try {
					Object.keys(translationState.sentenceTemplates[severity]);
				} catch (e) { return; }
				// Get the respective sentence template, this is a slightly modified version of handleOptionSelect 
				Object.keys(translationState.sentenceTemplates[severity]).forEach(section => {
					Object.keys(translationState.sentenceTemplates[severity][section]).forEach(element => {
						const option: string = translationState.sentenceTemplates[severity][section][element][0].display;

						if (observationState[section][element].value === '') {
							if (observationState[section][element].value === option) {
								if (observationState[section][element].editedValLabel === option) {
									observationState[section][element].output = observationState[section][element].editedVal.trim();
								} else {
									observationState[section][element].output = translationState.sentenceTemplates[severity][section][element].filter((elementOption: Option) => elementOption.display === option)[0].sentence.replace(/m2/g, 'm²').replace('[', '').replace(']', '');
									Object.keys(translationState.measurementLabelMap).forEach(key => {
										const varLabel = getSRPathLabel(key);

										const reportLabel = translationState.measurementLabelMap[key];
										if (observationState[section][element].output.includes(varLabel)) {
											observationState[section][element].output = observationState[section][element].output.replace(varLabel, reportLabel);

											const hasMeasurement = observationState[section][element].output.match(`${reportLabel}\\s?\\d+`);
											if (hasMeasurement === null) {
												observationState[section][element].output = observationState[section][element].output
													.replace(reportLabel, '')
													.replace('(  , ', '(')
													.replace('( , ', '(')
													.replace(',  ,', ',')
													.replace(',  .', '.')
													.replace(',  )', ')');
											}
										}
									});
								}
							} else {
								observationState[section][element].output = translationState.sentenceTemplates[severity][section][element].filter((elementOption: Option) => elementOption.display === option)[0].sentence.replace(/m2/g, 'm²').replace('[', '').replace(']', '');
								Object.keys(translationState.measurementLabelMap).forEach(key => {
									const varLabel = getSRPathLabel(key);
									const reportLabel = translationState.measurementLabelMap[key];

									if (observationState[section][element].output.includes(varLabel)) {
										observationState[section][element].output = observationState[section][element].output.replace(varLabel, reportLabel);

										const hasMeasurement = observationState[section][element].output.match(`${reportLabel}\\s?\\d+`);
										if (hasMeasurement === null) {
											observationState[section][element].output = observationState[section][element].output
												.replace(reportLabel, '')
												.replace('(  , ', '(')
												.replace('( , ', '(')
												.replace(',  ,', ',')
												.replace(',  .', '.')
												.replace(',  )', ')');
										}
									}
								});
							}

							observationState[section].sentence = Object.keys(observationState[section]).map(ele => observationState[section][ele].output).filter(x => x !== undefined && x !== '').join(' ');

							if (observationState[section][element].value !== option) {
								observationState[section][element].value = option;
							}
						}
					});
				});
			}
		});
	};

	// add a sentence to the output when a measurement has  
	const handleOptionSelect = (section: string, element: string, option: string): void => {
		runInAction(() => {
			if (option === 'N/A') {
				observationState[section][element].output = '';
				observationState[section][element].value = '';
				observationState[section].sentence = Object.keys(observationState[section]).map(ele => observationState[section][ele].output).filter(x => x !== undefined && x !== '').join(' ');
				return;
			}

			if (observationState[section] === undefined) return;

			if (observationState[section][element].value === option) {
				if (observationState[section][element].editedValLabel === option) {
					observationState[section][element].output = observationState[section][element].editedVal.trim();
				} else {
					observationState[section][element].output = translationState.translations[section][element].filter((elementOption: Option) => elementOption.display === option)[0].sentence.replace(/m2/g, 'm²').replace('[', '').replace(']', '');
					Object.keys(translationState.measurementLabelMap).forEach(key => {
						const varLabel = getSRPathLabel(key);

						const reportLabel = translationState.measurementLabelMap[key];
						if (observationState[section][element].output.includes(varLabel)) {
							observationState[section][element].output = observationState[section][element].output.replace(varLabel, reportLabel);

							const hasMeasurement = observationState[section][element].output.match(`${reportLabel}\\s?\\d+`);
							if (hasMeasurement === null) {
								observationState[section][element].output = observationState[section][element].output
									.replace(reportLabel, '')
									.replace('(  , ', '(')
									.replace('( , ', '(')
									.replace(',  ,', ',')
									.replace(',  .', '.')
									.replace(',  )', ')');
							}
						}
					});
				}
			} else {
				observationState[section][element].output = translationState.translations[section][element].filter((elementOption: Option) => elementOption.display === option)[0].sentence.replace(/m2/g, 'm²').replace('[', '').replace(']', '');
				Object.keys(translationState.measurementLabelMap).forEach(key => {
					const varLabel = getSRPathLabel(key);
					const reportLabel = translationState.measurementLabelMap[key];

					if (observationState[section][element].output.includes(varLabel)) {
						observationState[section][element].output = observationState[section][element].output.replace(varLabel, reportLabel);

						const hasMeasurement = observationState[section][element].output.match(`${reportLabel}\\s?\\d+`);
						if (hasMeasurement === null) {
							observationState[section][element].output = observationState[section][element].output
								.replace(reportLabel, '')
								.replace('(  , ', '(')
								.replace('( , ', '(')
								.replace(',  ,', ',')
								.replace(',  .', '.')
								.replace(',  )', ')');
						}
					}
				});
			}

			observationState[section].sentence = Object.keys(observationState[section]).map(ele => observationState[section][ele].output).filter(x => x !== undefined && x !== '').join(' ');

			if (observationState[section][element].value !== option) {
				observationState[section][element].value = option;
			}
		});
	};

	const handleEditOptionOutput = (section: string, element: string, newVal: string): void => {
		runInAction(() => {
			observationState[section][element].output = newVal.trim();
			observationState[section][element].editedVal = newVal.trim();
			observationState[section][element].editedValLabel = observationState[section][element].value;
		});
	};

	const resetSection = (section: string) => {
		runInAction(() => {
			Object.keys(observationState[section]).forEach(element => {
				if (element !== 'sentence') {
					observationState[section][element].value = '';
					observationState[section][element].output = '';
					observationState[section][element].editedVal = '';
					observationState[section][element].editedValLabel = '';
				}
			});
		});
	};

	// get the observation structure from the report entity 
	const setObservationState = (): void => {
		runInAction(() => {
			if (Object.keys(translationState.translations).length > 0) {
				Object.keys(translationState.translations).forEach(segment => {
					observationState[segment] = { sentence: '' };
					Object.keys(translationState.translations[segment]).forEach(element => {
						observationState[segment][element] = { value: '', conclusion: false, output: '' };
					});
				});
			}

			if (study.report) {
				if (study.report.reportDataGenerated !== null && study.report.reportDataGenerated !== '' && study.report.reportDataGenerated !== undefined) {
					const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);
					Object.keys(advancedReportDetails.observation).forEach(segment => {
						if (observationState[segment] === null) {
							observationState[segment] = advancedReportDetails.observation[segment];
						} else {
							if (observationState[segment] === undefined) return;
							observationState[segment].sentence = advancedReportDetails.observation[segment].sentence;
							Object.keys(advancedReportDetails.observation[segment]).forEach(element => {
								observationState[segment][element] = advancedReportDetails.observation[segment][element];
							});
						}
					});
				}
			}
		});
	};

	// get the demographic info of the study 
	const setDemographicInfo = (): void => {
		runInAction(() => {
			if (study.sonographer) {
				observationState.demographic.sonographer = study.sonographer;
			}
			if (study.clinicalDetails) {
				observationState.demographic.clinicalDetails = study.clinicalDetails;
			}
			if (study.doctorss.filter(d => d.doctorType === 'REFERRING').length > 0) {
				const referringDoctor = study.doctorss.filter(d => d.doctorType === 'REFERRING')[0];
				observationState.demographic.referringDoctorPrefix = referringDoctor.prefix;
				observationState.demographic.referringDoctorFirstName = referringDoctor.firstName;
				observationState.demographic.referringDoctorLastName = referringDoctor.lastName;
			}
			if (study.doctorss.filter(d => d.doctorType === 'REPORTING').length > 0) {
				const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING')[0];
				observationState.demographic.reportingDoctor = `${reportingDoctor.prefix} ${reportingDoctor.firstName} ${reportingDoctor.lastName}`;
			}
			if (study.report) {
				if (study.report.reportDataGenerated) {
					const reportData = JSON.parse(study.report.reportDataGenerated);

					if (reportData.observation?.demographic?.BSA) {
						if (reportData.observation?.demographic?.BSA === 0) {
							observationState.demographic.BSA = '';
						} else {
							observationState.demographic.BSA = reportData.observation.demographic.BSA;
						}
					}
					
					if (reportData.observation?.demographic?.DBP) {
						if (reportData.observation?.demographic?.DBP === 0) {
							observationState.demographic.DBP = '';
						} else {
							observationState.demographic.DBP = reportData.observation.demographic.DBP;
						}
					}
					
					if (reportData.observation?.demographic?.SBP) {
						if (reportData.observation?.demographic?.SBP === 0) {
							observationState.demographic.SBP = '';
						} else {
							observationState.demographic.SBP = reportData.observation.demographic.SBP;
						}
					}
				}
			}
		});
	};

	const getCWPAEDP = (): void => {
		const measurementEntities = study.measurementss.filter(m => m.name === 'CW-PREPGRAD');
		const measurementEntitiesTwo = study.measurementss.filter(m => m.name === 'CW-RAP');
		let measurementOne;
		let measurementTwo;

		if (measurementEntities && measurementEntitiesTwo) {
			[measurementOne] = measurementEntities;
			[measurementTwo] = measurementEntitiesTwo;
		}

		if (reportMeasurementsState.reportMeasurementsObject !== undefined && reportMeasurementsState.reportMeasurementsObject['CWPREPGRAD'] !== undefined) {
			measurementOne = reportMeasurementsState.reportMeasurementsObject['CWPREPGRAD'] as MeasurementEntity;
		}
		if (reportMeasurementsState.reportMeasurementsObject !== undefined && reportMeasurementsState.reportMeasurementsObject['CWRAP'] !== undefined) {
			measurementTwo = reportMeasurementsState.reportMeasurementsObject['CWRAP'] as MeasurementEntity;
		}

		if (measurementOne && measurementTwo) {
			measurements['CWPAEDP'].value = parseFloat((measurementOne.value + measurementTwo.value).toFixed(PrecisionMap['CWPAEDP']));
			measurements['CWPAEDP'].unit = DefaultUnitMap['CWPAEDP'];

			translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
			calculatedMeasurementsState['CWPAEDP'] = measurements['CWPAEDP'];
		}
	};

	const getCWTRPGRAD = (): void => {
		const measurementEntities = study.measurementss.filter(m => m.name === 'CW-TRVMAX');
		let measurementOne;

		if (measurementEntities) {
			[measurementOne] = measurementEntities;
		}

		if (reportMeasurementsState.reportMeasurementsObject !== undefined && reportMeasurementsState.reportMeasurementsObject['CWTRVMAX'] !== undefined) {
			measurementOne = reportMeasurementsState.reportMeasurementsObject['CWTRVMAX'] as MeasurementEntity;
		}

		if (measurementOne) {
			measurements['CWTRPGRAD'].value = parseFloat((4 * (measurementOne.value * measurementOne.value)).toFixed(PrecisionMap['CWTRPGRAD']));
			measurements['CWTRPGRAD'].unit = DefaultUnitMap['CWTRPGRAD'];

			translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
			calculatedMeasurementsState['CWTRPGRAD'] = measurements['CWTRPGRAD'];
		}
	};

	const getRVSP = (): void => {
		if (measurements.CWTRPGRAD.value && measurements.CWRAP.value) {
			let measurementOne = measurements.CWTRPGRAD;
			let measurementTwo = measurements.CWRAP;

			if (reportMeasurementsState.reportMeasurementsObject !== undefined && reportMeasurementsState.reportMeasurementsObject['CWTRPGRAD'] !== undefined) {
				measurementOne = reportMeasurementsState.reportMeasurementsObject['CWTRPGRAD'] as MeasurementEntity;
			}
			if (reportMeasurementsState.reportMeasurementsObject !== undefined && reportMeasurementsState.reportMeasurementsObject['CWRAP'] !== undefined) {
				measurementTwo = reportMeasurementsState.reportMeasurementsObject['CWRAP'] as MeasurementEntity;
			}

			if (measurementOne.value && measurementTwo.value) {
				let temp = (Number(measurementOne.value) + Number(measurementTwo.value)).toFixed(PrecisionMap.CWRVSP);
				measurements['CWRVSP'].value = parseFloat(temp);
				measurements['CWRVSP'].unit = DefaultUnitMap['CWRVSP'];
				
				translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
				calculatedMeasurementsState['CWRVSP'] = measurements['CWRVSP'];
			}	
		}
	};

	// get BSA measurement, aka index measurement 
	const getBSAMeasurement = (key: string, measurementLabel: string, measurementLabelTwo?: string) => {
		const measurementEntities = study.measurementss.filter(m => m.name === measurementLabel);
		const altLabel = getVariableLabel(measurementLabel);

		runInAction(() => {
			if (measurementLabelTwo) {
				const measurementEntitiesTwo = study.measurementss.filter(m => m.name === measurementLabelTwo);
				const altLabelTwo = getVariableLabel(measurementLabelTwo);
				let measurementOne;
				let measurementTwo;

				if (reportMeasurementsState.reportMeasurementsObject[altLabel] !== undefined) {
					measurementOne = reportMeasurementsState.reportMeasurementsObject[altLabel];
				} else if (measurementEntities.length > 0) {
					[measurementOne] = measurementEntities;
				}

				if (reportMeasurementsState.reportMeasurementsObject[altLabelTwo] !== undefined) {
					measurementTwo = reportMeasurementsState.reportMeasurementsObject[altLabelTwo];
				} else if (measurementEntitiesTwo.length > 0) {
					[measurementTwo] = measurementEntitiesTwo;
				}

				if (key === 'CAVDI') {
					if (measurementOne && measurementTwo && measurementOne.unit === measurementTwo.unit) {
						measurements[key].value = parseFloat((measurementOne.value / measurementTwo.value).toFixed(PrecisionMap[key]));
						measurements[key].unit = DefaultUnitMap[key];
						translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
						calculatedMeasurementsState[key] = measurements[key];
					}
				}

				if (key === 'PWMVADURPWPVEINARDUR') {
					if (measurementOne && measurementTwo && measurementOne.unit === measurementTwo.unit) {
						measurements[key].value = parseFloat((measurementOne.value - measurementTwo.value).toFixed(PrecisionMap[key]));
						measurements[key].unit = DefaultUnitMap[key];
						translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
						calculatedMeasurementsState[key] = measurements[key];
					}
				}
			} else if (reportMeasurementsState.reportMeasurementsObject[altLabel] !== undefined && observationState.demographic.BSA !== '0') {
				measurements[key].value = (reportMeasurementsState.reportMeasurementsObject[altLabel].value / parseFloat(observationState.demographic.BSA)).toFixed(PrecisionMap[key]);
				measurements[key].unit = `${reportMeasurementsState.reportMeasurementsObject[altLabel].unit}/m²`;
				translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
				calculatedMeasurementsState[key] = measurements[key];
			} else if (measurementEntities.length > 0 && observationState.demographic.BSA !== '' && observationState.demographic.BSA !== '0') {
				measurements[key].value = (measurementEntities[0].value / parseFloat(observationState.demographic.BSA)).toFixed(PrecisionMap[key]);
				measurements[key].unit = `${measurementEntities[0].unit}/m²`;
				translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
				calculatedMeasurementsState[key] = measurements[key];
			} else {
				// do nothing 
			}
		});
	};

	// retrieve the measurement either from the first recorded measurement, or the specified measurement on the measurement tile 
	const getMeasurement = (key: string): void => {
		const measurementEntities = study.measurementss.filter(m => m.name === getSRPathLabel(key));

		if (reportMeasurementsState.reportMeasurementsObject !== undefined && reportMeasurementsState.reportMeasurementsObject[key] !== undefined) {
			const { value, unit } = reportMeasurementsState.reportMeasurementsObject[key] as MeasurementEntity;
			measurements[key].value = typeof value === 'string' ? parseFloat(value).toFixed(PrecisionMap[key]) : value.toFixed(PrecisionMap[key]);
			measurements[key].unit = unit;
		} else if (measurementEntities.length > 0) {
			measurements[key].value = measurementEntities[0].value.toFixed(PrecisionMap[key]);
			measurements[key].unit = measurementEntities[0].unit;
		}
	};

	// update a specific measurement 
	const updateMeasurement = (measurementLabel: string, measurement: MeasurementEntity): void => {
		const altLabel = getVariableLabel(measurementLabel);
		runInAction(() => {
			measurements[altLabel].value = measurement.value.toFixed(PrecisionMap[altLabel]);
			measurements[altLabel].unit = measurement.unit;
			const indexMeasurements = ['C-AVAVTI', '(2D)LVEDVMODBP', '(2D)AOROOT', '(2D)ASCAO', '(2D)LAEDVMODBP', '(2D)LAEDVMODAL', '(2D)LVIDD', '(MM)LVIDD'];
			if (indexMeasurements.includes(measurementLabel)) {
				getBSAMeasurement(`${altLabel}Index`, measurementLabel);
			}
			if (measurementLabel === '(MM)LVMASScubed') {
				getBSAMeasurement('MMLVMI', measurementLabel);
			}
			if (measurementLabel === '(2D)LVMASScubed') {
				getBSAMeasurement('$2DLVMI', measurementLabel);
			}
			if (measurementLabel === 'CW-AVVTI' || measurementLabel === 'PW-LVOTVTI') {
				getBSAMeasurement('CAVDI', 'PW-LVOTVTI', 'CW-AVVTI');
			}
			if (measurementLabel === 'PW-MVADUR' || measurementLabel === 'PW-PVEINARDUR') {
				getBSAMeasurement('PWMVADURPWPVEINARDUR', 'PW-MVADUR', 'PW-PVEINARDUR');
			}

			if (measurementLabel === 'CW-RAP' || measurementLabel === 'CW-PREPGRAD') {
				getCWPAEDP();
			}

			if (measurementLabel === 'CW-TRVMAX') {
				getCWTRPGRAD();
				getRVSP();
			}

			if (measurementLabel === 'CW-TRPGRAD' || measurementLabel === 'CW-RAP') {
				getRVSP();
			}

			runInAction(() => {
				updateState.update = true;
			});
		});
	};

	// set the initial measurement state 
	const setMeasurementState = (): void => {
		Object.keys(measurements).forEach(key => {
			runInAction(() => {
				if (key === '$2DLVEDVMODBPIndex') {
					getBSAMeasurement('$2DLVEDVMODBPIndex', '(2D)LVEDVMODBP');
				} else if (key === '$2DAOROOTIndex') {
					getBSAMeasurement('$2DAOROOTIndex', '(2D)AOROOT');
				} else if (key === '$2DASCAOIndex') {
					getBSAMeasurement('$2DASCAOIndex', '(2D)ASCAO');
				} else if (key === '$2DLAEDVMODBPIndex') {
					getBSAMeasurement('$2DLAEDVMODBPIndex', '(2D)LAEDVMODBP');
				} else if (key === '$2DLAEDVMODALIndex') {
					getBSAMeasurement('$2DLAEDVMODALIndex', '(2D)LAEDVMODAL');
				} else if (key === '$2DLVIDDIndex') {
					getBSAMeasurement('$2DLVIDDIndex', '(2D)LVIDD');
				} else if (key === 'MMLVIDDIndex') {
					getBSAMeasurement('MMLVIDDIndex', '(MM)LVIDD');
				} else if (key === 'CAVDI') {
					getBSAMeasurement('CAVDI', 'PW-LVOTVTI', 'CW-AVVTI');
				} else if (key === 'CAVAVTIIndex') {
					getBSAMeasurement('CAVAVTIIndex', 'C-AVAVTI');
				} else if (key === 'MMLVMASScubed') {
					getBSAMeasurement('MMLVMI', '(MM)LVMASScubed');
				} else if (key === '$2DLVMASScubed') {
					getBSAMeasurement('$2DLVMI', '(2D)LVMASScubed');
				} else if (key === 'PWMVADURPWPVEINARDUR') {
					getBSAMeasurement('PWMVADURPWPVEINARDUR', 'PW-MVADUR', 'PW-PVEINARDUR');
				} else if (key === 'CWPAEDP') {
					getCWPAEDP();
				} else if (key === 'CWTRPGRAD') {
					getCWTRPGRAD();
				} else if (key === 'CWRVSP') {
					getRVSP();
				} else {
					getMeasurement(key);
				}
			});
		});
	};

	const setFocusedFrame = (step: number) => {
		if (viewerState.view === 'grid') {
			gridDicomViewer1 = document.getElementById('DICOMImageViewerGrid1');
			gridDicomViewer2 = document.getElementById('DICOMImageViewerGrid2');
			gridDicomViewer3 = document.getElementById('DICOMImageViewerGrid3');
			gridDicomViewer4 = document.getElementById('DICOMImageViewerGrid4');

			const grid1stackData = cornerstoneTools.getToolState(gridDicomViewer1, 'stack');
			const grid2stackData = cornerstoneTools.getToolState(gridDicomViewer2, 'stack');
			const grid3stackData = cornerstoneTools.getToolState(gridDicomViewer3, 'stack');
			const grid4stackData = cornerstoneTools.getToolState(gridDicomViewer4, 'stack');

			let grid1FrameIndex = grid1stackData.data[0].currentImageIdIndex + step;
			let grid2FrameIndex = grid2stackData.data[0].currentImageIdIndex + step;
			let grid3FrameIndex = grid3stackData.data[0].currentImageIdIndex + step;
			let grid4FrameIndex = grid4stackData.data[0].currentImageIdIndex + step;

			if (grid1FrameIndex >= grid1stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid1FrameIndex = 0;
			}

			if (grid2FrameIndex >= grid2stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid2FrameIndex = 0;
			}

			if (grid3FrameIndex >= grid3stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid3FrameIndex = 0;
			}

			if (grid4FrameIndex >= grid4stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				grid4FrameIndex = 0;
			}

			scrollToIndex(gridDicomViewer1, grid1FrameIndex);
			scrollToIndex(gridDicomViewer2, grid2FrameIndex);
			scrollToIndex(gridDicomViewer3, grid3FrameIndex);
			scrollToIndex(gridDicomViewer4, grid4FrameIndex);
		} else if (viewerState.view === 'stress') {
			dicomViewerBaseline = document.getElementById('dicomViewerBaseline');
			dicomViewerPeak = document.getElementById('dicomViewerPeak');
			dicomViewerPost = document.getElementById('dicomViewerPost');
			dicomViewerRecovery = document.getElementById('dicomViewerRecovery');

			const dicomViewerBaselineStackData = cornerstoneTools.getToolState(dicomViewerBaseline, 'stack');
			const dicomViewerPeakStackData = cornerstoneTools.getToolState(dicomViewerPeak, 'stack');
			const dicomViewerPostStackData = cornerstoneTools.getToolState(dicomViewerPost, 'stack');
			const dicomViewerRecoveryStackData = cornerstoneTools.getToolState(dicomViewerRecovery, 'stack');

			let baseFrameIndex = dicomViewerBaselineStackData !== undefined ? dicomViewerBaselineStackData.data[0].currentImageIdIndex + step : null;
			let peakFrameIndex = dicomViewerPeakStackData !== undefined ? dicomViewerPeakStackData.data[0].currentImageIdIndex + step : null;
			let postFrameIndex = dicomViewerPostStackData !== undefined ? dicomViewerPostStackData.data[0].currentImageIdIndex + step : null;
			let recoveryFrameIndex = dicomViewerRecoveryStackData !== undefined ? dicomViewerRecoveryStackData.data[0].currentImageIdIndex + step : null;

			if (baseFrameIndex && baseFrameIndex >= dicomViewerBaselineStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				baseFrameIndex = 0;
			}

			if (peakFrameIndex && peakFrameIndex >= dicomViewerPeakStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				peakFrameIndex = 0;
			}

			if (postFrameIndex && postFrameIndex >= dicomViewerPostStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				postFrameIndex = 0;
			}

			if (recoveryFrameIndex && recoveryFrameIndex >= dicomViewerRecoveryStackData.data[0].imageIds.length) {
				// Reset frame to beginning
				recoveryFrameIndex = 0;
			}

			scrollToIndex(dicomViewerBaseline, baseFrameIndex);
			scrollToIndex(dicomViewerPeak, peakFrameIndex);
			scrollToIndex(dicomViewerPost, postFrameIndex);
			scrollToIndex(dicomViewerRecovery, recoveryFrameIndex);
		} else {
			heroDicomViewer = document.getElementById('DICOMImageViewerStd');

			// Get index of current DICOM frame from Stack Tool state 
			const stackData = cornerstoneTools.getToolState(heroDicomViewer, 'stack');

			// Increment current index by step value (may be a negative value) 
			let currentFrameIndex = stackData.data[0].currentImageIdIndex + step;

			// Check we haven't gone beyond the bounds of imageIds array 
			if (currentFrameIndex >= stackData.data[0].imageIds.length) {
				// Reset frame to beginning 
				currentFrameIndex = 0;
			}

			// Manually scroll to this frame in DICOM Viewer 
			scrollToIndex(heroDicomViewer, currentFrameIndex);
		}
	};

	// Only update index measurements 
	const updateBSAMeasurements = (): void => {
		getBSAMeasurement('MMLVIDDIndex', '(MM)LVIDD');
		getBSAMeasurement('$2DLVIDDIndex', '(2D)LVIDD');
		getBSAMeasurement('$2DLVEDVMODBPIndex', '(2D)LVEDVMODBP');
		getBSAMeasurement('$2DLAEDVMODBPIndex', '(2D)LAEDVMODBP');
		getBSAMeasurement('$2DLAEDVMODALIndex', '(2D)LAEDVMODAL');
		getBSAMeasurement('$2DAOROOTIndex', '(2D)AOROOT');
		getBSAMeasurement('$2DASCAOIndex', '(2D)ASCAO');
		getBSAMeasurement('CAVAVTIIndex', 'C-AVAVTI');
		getBSAMeasurement('CAVDI', 'PW-LVOTVTI', 'CW-AVVTI');
		getBSAMeasurement('PWMVADURPWPVEINDARDUR', 'PW-MVADUR', 'PW-PVEINARDUR');
		getBSAMeasurement('MMLVMI', '(MM)LVMASScubed');
		getBSAMeasurement('$2DLVMI', '(2D)LVMASScubed');
	};

	// if the condition requires multiple measurements to be met 
	const multiConditional = (section: string, element: string, gender: string, measurementLabel: string): void => {
		const measurementlabels = measurementLabel.split(' && ');
		const recordedMeasurements = [] as MeasurementEntity[];
		measurementlabels.forEach(label => {
			if (measurements[getVariableLabel(label)].value !== undefined) {
				recordedMeasurements.push(measurements[getVariableLabel(label)]);
			}
		});

		const matches = [] as string[];
		recordedMeasurements.forEach(recordedMeasurement => {
			for (let i = 0; i <= Object.keys(translationState.prepopulationConditions[section][element][gender][measurementLabel]).length - 1; i += 2) {
				const keyOne = Object.keys(translationState.prepopulationConditions[section][element][gender][measurementLabel])[i];
				const keyTwo = Object.keys(translationState.prepopulationConditions[section][element][gender][measurementLabel])[i + 1];
				if (recordedMeasurement.value >= translationState.prepopulationConditions[section][element][gender][measurementLabel][keyOne]
                    && recordedMeasurement.value <= translationState.prepopulationConditions[section][element][gender][measurementLabel][keyTwo]
				) {
					const optionKey = Object.keys(translationState.prepopulationConditions[section][element])[i / 2];
					matches.push(optionKey);
				}
			}
		});
		if (matches.length > 1 && [...new Set(matches)].length === 1) {
			if (observationState[section] === undefined || observationState[section][element].value === '' || observationState[section][element].value === null || observationState[section][element].value === undefined) {
				handleOptionSelect(section, element, translationState.prepopulationConditions[section][element][matches[0]]);
			}
		}
	};

	// if the condition only requires a single measurement comparison 
	const singleConditional = (section: string, element: string, gender: string, measurementLabel: string): void => {
		const recordedMeasurement = measurements[getVariableLabel(measurementLabel)];

		if (recordedMeasurement.value !== undefined) {
			for (let i = 0; i <= Object.keys(translationState.prepopulationConditions[section][element][gender][measurementLabel]).length - 1; i += 2) {
				const keyOne = Object.keys(translationState.prepopulationConditions[section][element][gender][measurementLabel])[i];
				const keyTwo = Object.keys(translationState.prepopulationConditions[section][element][gender][measurementLabel])[i + 1];
				if (recordedMeasurement.value >= translationState.prepopulationConditions[section][element][gender][measurementLabel][keyOne]
                    && recordedMeasurement.value <= translationState.prepopulationConditions[section][element][gender][measurementLabel][keyTwo]
				) {
					if (observationState[section] === undefined || observationState[section][element].value === '' || observationState[section][element].value === null || observationState[section][element].value === undefined) {
						const optionKey = Object.keys(translationState.prepopulationConditions[section][element])[i / 2];
						handleOptionSelect(section, element, translationState.prepopulationConditions[section][element][optionKey]);
					}
				}
			}
		}
	};

	// determine which measurements are required based on gender 
	const genderOptions = (section: string, element: string): void => {
		const gender = study.patient.gender.toString().toLowerCase();
		if (gender === 'male' || gender === 'female') {
			Object.keys(translationState.prepopulationConditions[section][element][gender]).forEach(measurementLabel => {
				if (measurementLabel.includes('&&')) {
					multiConditional(section, element, gender, measurementLabel);
				} else {
					singleConditional(section, element, gender, measurementLabel);
				}
			});
		} else {
			// do nothing; 
		}
	};

	// complete comparison when gender is not a factor 
	const neutralOptions = (section: string, element: string): void => {
		Object.keys(translationState.prepopulationConditions[section][element]).forEach(measurementLabel => {
			const recordedMeasurement = measurements[getVariableLabel(measurementLabel)];
			if (recordedMeasurement !== undefined && recordedMeasurement.value !== undefined) {
				for (let i = 0; i <= Object.keys(translationState.prepopulationConditions[section][element][measurementLabel]).length - 1; i += 2) {
					const keyOne = Object.keys(translationState.prepopulationConditions[section][element][measurementLabel])[i];
					const keyTwo = Object.keys(translationState.prepopulationConditions[section][element][measurementLabel])[i + 1];
					if (recordedMeasurement.value >= translationState.prepopulationConditions[section][element][measurementLabel][keyOne]
                        && recordedMeasurement.value <= translationState.prepopulationConditions[section][element][measurementLabel][keyTwo]
					) {
						if (observationState[section][element].value === '' || observationState[section][element].value === null || observationState[section][element].value === undefined) {
							const optionKey = Object.keys(translationState.prepopulationConditions[section][element])[i / 2];
							handleOptionSelect(section, element, translationState.prepopulationConditions[section][element][optionKey]);
						}
					}
				}
			}
		});
	};

	// run through the pre-condtions and select observations accordingly 
	const setPreSelection = (): void => {
		Object.keys(translationState.prepopulationConditions).forEach(section => {
			Object.keys(translationState.prepopulationConditions[section]).forEach(element => {
				if (Object.keys(translationState.prepopulationConditions[section][element]).includes('male')) {
					genderOptions(section, element);
				} else {
					neutralOptions(section, element);
				}
			});
		});
	};

	// get the site from the study 
	const retrieveSiteInfo = (): void => {
		store.apolloClient.query({
			query: SiteEntity.fetchSingleSite(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: study.patient.siteId,
				}],
			},
		}).then(res => {
			runInAction(() => {
				siteState.site = new SiteEntity({ ...res.data.siteEntity });
				reportState.builder = siteState.site.advancedReportBuilder ? 'advanced' : 'basic';
				
				// Show basic read only when confirmed
				if (study.report?.reportStatus === 'CONFIRMED' && reportState.builder === 'advanced') {
					reportState.builder = 'preview';
				}
			});

			axios.get(`${SERVER_URL}/api/entity/SiteEntity/${study.patient.siteId}`)
				.then(siteRes => {
					if (siteRes.data !== '') {
						runInAction(() => {
							siteState.site.translationFileId = siteRes.data.translationFileId;
							if (siteState.site.translationFileId) {
								axios.get(`${SERVER_URL}/api/files/${siteState.site.translationFileId}?download=true`).then(translationRes => {
									const translationFile = translationRes.data;
									i18n.addResourceBundle('en', siteState.site.siteName, translationFile);
									runInAction(() => {
										languageState.namespace = `${siteState.site.siteName}:`;
									});
								}).catch((error: Error) => {
									console.error(error);
								});
							} else {
								runInAction(() => {
									// @ts-ignore
									i18n.removeResourceBundle('en', siteState.site.siteName);

									runInAction(() => {
										languageState.namespace = '';
									});
								});
							}
						});
					}
				}).catch(err => console.error(err));
		}).catch(error => {
			console.error(error);
		});
	};

	// keyboard shortcut definitions 
	const toggleShortcutsModal = (): void => {
		store.setShortcutModal(!store.shortcutModal);

		if (store.shortcutModal) {
			store.modal.show('Keyboard Shortcuts', (
				<div>
					<h4>Keyboard Shortcuts</h4>
					<ul>
						<li>Ctrl + Space - Toggles Play/Pause</li>
						<li>Alt + G - Toggles Grid/Standard View</li>
						<li>Alt + H - Toggles Fullscreen View</li>
						<li>Alt + R - Toggles Report Panel</li>

						<li>End key - Next Frame</li>
						<li>Home key - Previous Frame</li>
						<li>PgUp - Previous Image</li>
						<li>PgDn - Next Image</li>
					</ul>
				</div>
			));
		} else {
			store.modal.hide();
		}
	};

	const onTabChange = (currentTab: string): void => {
		if (reportState.builder !== currentTab) {
			if (viewerState.view !== 'grid') {
				heroDicomViewer = document.getElementById('DICOMImageViewerStd');

				if (heroDicomViewer) {
					if (reportState.builder === 'none') {
						heroDicomViewer.style.display = 'none';
						onResize('DICOMImageViewerStd');
					}
				}

				runInAction(() => {
					reportState.builder = currentTab;
					cornerstoneState.fullScreen = false;
				});

				if (heroDicomViewer) {
					heroDicomViewer.style.display = 'flex';
				}

				updateHero(cornerstoneState.heroId);
			} else {
				runInAction(() => {
					reportState.builder = currentTab;
					cornerstoneState.fullScreen = false;
				});
			}
		} else {
			runInAction(() => {
				reportState.builder = 'none';
				cornerstoneState.fullScreen = true;
			});
		}
		
		if (currentTab === 'preview') {
			fetchPreview();
		}
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.code === 'KeyG' && event.altKey) {
			if (viewerState.view !== 'grid') {
				changeViewerState('grid');
			} else {
				changeViewerState('std');
			}
		}
		
		if (event.code === 'KeyH' && event.altKey) {
			if (viewerState.view !== 'full') {
				changeViewerState('full');
			} else {
				changeViewerState('std');
			}
		}
		
		if (event.code === 'KeyR' && event.altKey) {
			if (reportState.builder !== 'none') {
				onTabChange(reportState.builder);
			} else {
				onTabChange('basic');
			}
		}
		
		if (event.code === 'Space' && event.ctrlKey) {
			event.stopPropagation();
			event.preventDefault();
			pausePlayButton();
		}

		if (event.code === 'Home') {
			event.preventDefault();
			setFocusedFrame(-1);
		}

		if (event.code === 'End') {
			event.preventDefault();
			setFocusedFrame(1);
		}
		
		if (event.code === 'PageUp') {
			event.preventDefault();
			
			if (viewerState.view === 'grid') {
				// Move in 4s
				if (cornerstoneState.heroId > 4) {
					updateHero(cornerstoneState.heroId - 4);
				} else {
					updateHero(cornerstoneState.imageIds.length - 4)
				}
			} else if (viewerState.view === 'stress') {
				changeStressView(-1);
			} else if (cornerstoneState.heroId > 0) { //when the heroId isn't on the last image of the study 
				updateHero(cornerstoneState.heroId - 1);
			} else if (cornerstoneState.heroId === 0) { //when heroId is on last image of the study, loop back to the 1st image of the study 
				updateHero(cornerstoneState.imageIds.length - 1);
			}
		}
	
		if (event.code === 'PageDown') {
			event.preventDefault();
			if (viewerState.view === 'grid') {
				if (cornerstoneState.heroId < cornerstoneState.imageIds.length - 4) {
					updateHero(cornerstoneState.heroId + 4);
				} else {
					updateHero(0)
				}
			} else if (viewerState.view === 'stress') {
				changeStressView(1);
			} else if (cornerstoneState.heroId < cornerstoneState.imageIds.length - 1) { //when the heroId isn't on the first image of the study 
				updateHero(cornerstoneState.heroId + 1);
			} else if (cornerstoneState.heroId > cornerstoneState.imageIds.length - 2) { //when heroId is on first image of the study, loop back to the last image of the study 
				updateHero(cornerstoneState.heroId = 0);
			}
		}
	};
	
	const fetchPreview = () => {
		axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportPDFFromTemplate/${study.id}/${study.selectedReportTemplate}`)
			.then(async res => runInAction(() => {
				pdfs.preview = `data:application/pdf;base64,${res.data}`;
			}))
			.catch(() => {
				alert('Failed to fetch echo report pdf', 'error');
			});
	}

	useEffect(() => {
		startUpViewer();
		retrieveSiteInfo();
		fetchDoctors();
		
		runInAction(() => {
			if (study.report && study.report.reportDataGenerated) {
				const advancedReportDetails = JSON.parse(study.report.reportDataGenerated);
				conclusionState.conclusions = advancedReportDetails.conclusion.conclusions;
			}
		});
		
		document.addEventListener('keydown', handleKeyDown);

		// Get all the associated DICOMs 
		// Check if legacy import  
		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/CheckStudyDicomThumbnails/${study.id}`)
			.then((res: AxiosResponse<boolean>) => {
				if (res.data) {
					// Get all the associated DICOMs
					getAllDicoms();
				} else {
					store.modal.show('Loading',
						<div className="upload-thumbnails">
							<h4>Thumbnails are being generated for this legacy study.</h4>
							<CustomSpinner />
						</div>);

					axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/GetDicomThumbnails/${study.id}`)
					.then((res: AxiosResponse<boolean>) => {
						if (res.data) {
							alert('Thumbnails finished generating', 'success');
							store.modal.hide();
							getAllDicoms();
						} else {
							store.modal.hide();
							alert('An error occured generating thumbnails, please reload', 'error');
						}
					}).catch(() => {
						store.modal.hide();
						alert('An error occured generating thumbnails, please reload', 'error');
					});
				}
			});
		
		return (): void => {
			runInAction(() => {
				cornerstoneState.imageIds = [];
			});

			cornerstone.imageCache.purgeCache();
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);
	
	useEffect(() => {
		// Site translation loaded
		
		if(languageState.namespace !== 'default') {
			runInAction(() => {
				translationState.prepopulationConditions = t(`${languageState.namespace}${studyType}.prepopulationConditions`, {returnObjects: true});
				translationState.measurementLabelMap = t(`${languageState.namespace}${studyType}.measurementPanel.measurementLabels`, {returnObjects: true});
				translationState.sentenceTemplates = t(`${languageState.namespace}${studyType}.sentenceTemplates`, {returnObjects: true});
			});

			updateTranslations();
			setObservationState();
			setDemographicInfo();
			setMeasurementState();
			updateBSAMeasurements();
			setPreSelection();

			runInAction(() => {
				loadingState.loading = false;
			});
		}

	}, [languageState.namespace]);

	const updateTranslations = (): void => {
		runInAction(() => {
			translationState.translations = t(`${languageState.namespace}${studyType}.advancedReportBuilder`, { returnObjects: true, ...measurements });
		});
	};
	
	const getReportClasses = (): string => {
		if (pdfs.twelveLead !== '' && study.studyType === 'STRESS_ECHO') {
			return (siteState.site.advancedReportBuilder && pdfs.preview !== '') ? 'stress-echo-twelve-advanced' : 'stress-echo-twelve';
		}
		
		if (pdfs.twelveLead !== '') {
			return (siteState.site.advancedReportBuilder && pdfs.preview !== '') ? 'twelve-advanced': 'twelve';
		}
		
		if (study.studyType === 'STRESS_ECHO') {
			return (siteState.site.advancedReportBuilder && pdfs.preview !== '') ? 'stress-echo-advanced' : 'stress-echo';
		}

		return (siteState.site.advancedReportBuilder && pdfs.preview !== '') ? 'advanced-preview' : '';
	}
	
	return (
		<div className={classNames('echo-study-viewer', store.hasBackendAccess && 'top-bar')}>

			<div className="viewports-panel">
				<div className="image-controls-overlay">
					<ButtonGroup>
						<Button
							className={classNames(viewerState.view === 'std' && 'active')}
							display={Display.Solid}
							colors={Colors.Success}
							icon={{ icon: 'cube' }}
							onClick={() => changeViewerState('std')}
						/>
						<Button
							className={classNames(viewerState.view === 'grid' && 'active')}
							display={Display.Solid}
							colors={Colors.Success}
							icon={{ icon: 'grid' }}
							onClick={() => changeViewerState('grid')}
						/>
						<Button
							className={classNames(viewerState.view === 'full' && 'active')}
							display={Display.Solid}
							colors={Colors.Success}
							icon={{ icon: 'expand-2' }}
							onClick={() => changeViewerState('full')}
						/>
					</ButtonGroup>
				</div>

				<div
					className="view-thumbnails"
					id="dicom-thumbnails"
					style={viewerState.view === 'full' || viewerState.view === 'stress' ? { display: 'none' } : {}}
				/>

				<div className={classNames('hero-view', cornerstoneState.fullScreen && 'full-screen')}>
					<div className="hero-viewport-wrapper" id="important">
						<div className="hero-viewport-info">
							<Button
								display={Display.Text}
								icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
								className="back-button"
								onClick={() => navigate(-1)}
							>
								Close Study
							</Button>
							<div className="study-info"> 
								<span> 
									Name:
									{' '}
									{study.patient.name} 
								</span>
								<span> 
									D.O.B:
									{' '}
									{moment(study.patient.dob).format('DD/MM/YYYY')} 
								</span>
								<span> 
									Exam Date:
									{' '}
									{moment(study.studyDate).format('DD/MM/YYYY')} 
								</span>
							</div>
						</div>

						<div
							className="hero-viewport-grid"
							style={viewerState.view !== 'grid' ? { display: 'none' } : {}}
						>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid1')}
							>
								<div id="DICOMImageViewerGrid1" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid2')}
							>
								<div id="DICOMImageViewerGrid2" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid3')}
							>
								<div id="DICOMImageViewerGrid3" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('DICOMImageViewerGrid4')}
							>
								<div id="DICOMImageViewerGrid4" className="viewport-wrapper" />
							</ReactResizeDetector>
						</div>

						<div
							className="hero-viewport-stress"
							style={viewerState.view !== 'stress' ? { display: 'none' } : {}}
						>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerBaseline')}
							>
								<div id="dicomViewerBaseline" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerPeak')}
							>
								<div id="dicomViewerPeak" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerPost')}
							>
								<div id="dicomViewerPost" className="viewport-wrapper" />
							</ReactResizeDetector>
							<ReactResizeDetector
								handleWidth
								handleHeight
								skipOnMount
								refreshMode="debounce"
								refreshRate={200}
								onResize={() => onResize('dicomViewerRecovery')}
							>
								<div id="dicomViewerRecovery" className="viewport-wrapper" />
							</ReactResizeDetector>
						</div>

						<ReactResizeDetector
							handleWidth
							handleHeight
							skipOnMount
							refreshMode="debounce"
							refreshRate={200}
							onResize={() => onResize('DICOMImageViewerStd')}
						>
							<div className="hero-viewport-std" style={(viewerState.view === 'grid' || viewerState.view === 'stress') ? { display: 'none' } : {}}>
								<div
									className="viewport-wrapper"
									id="DICOMImageViewerStd"
								/>
							</div>
						</ReactResizeDetector>

						<div className="function-controls-overlay">
							<ButtonGroup className="tool-menu">
								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => pausePlayButton()}
								>
									{cornerstoneState.isPlaying ? 'Pause' : 'Play'}
								</Button>

								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => slowHeroSpeed()}
								>
									Slower
								</Button>

								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => resetHeroSpeed()}
								>
									Reset
								</Button>

								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => quickenHeroSpeed()}
								>
									Faster
								</Button>
								<Button
									className={classNames(cornerstoneState.activeTool === 'Wwwc' ? 'function active' : 'function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => cornerstoneState.activeTool = 'Wwwc');
										cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
									}}
								>
									Contrast
								</Button>
								<Button
									className={classNames(cornerstoneState.activeTool === 'Length' ? 'function active' : 'function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => cornerstoneState.activeTool = 'Length');
										cornerstoneTools.setToolActive('Length', { mouseButtonMask: 1 });
									}}
								>
									Measure
								</Button>
								<Button
									className={classNames(cornerstoneState.activeTool === 'FreehandRoi' ? 'function active' : 'function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => cornerstoneState.activeTool = 'FreehandRoi');
										cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: 1 });
									}}
								>
									Area
								</Button>
								<Button
									className={classNames('function')}
									display={Display.Solid}
									colors={Colors.Secondary}
									sizes={Sizes.Small}
									onClick={() => toggleShortcutsModal()}
								>
									Shortcuts
								</Button>
								{study.studyType === 'STRESS_ECHO' && (
									<Button
										className={classNames(viewerState.view === 'stress' ? 'function active' : 'function')}
										display={Display.Solid}
										colors={Colors.Secondary}
										sizes={Sizes.Small}
										disabled={cornerstoneState.baseStacksList.length === 0}
										onClick={() => {
											changeViewerState('stress');
											onTabChange(reportState.builder);
										}}
									>
										Stress Echo
									</Button>
								)}
								{viewerState.view === 'stress' && (
									<>
										<Button
											className={classNames('function previous')}
											display={Display.Solid}
											colors={Colors.Secondary}
											sizes={Sizes.Small}
											onClick={() => changeStressView(-1)}
										>
											Previous
										</Button>
										<Button
											className={classNames('function next')}
											display={Display.Solid}
											colors={Colors.Secondary}
											sizes={Sizes.Small}
											onClick={() => changeStressView(1)}
										>
											Next
										</Button>
									</>
								)}
							</ButtonGroup>
						</div>
					</div>
				</div>
			</div>

			<div className={classNames('report_builder', cornerstoneState.fullScreen && 'hidden')}>
				{loadingState.loading ? (
					<CustomSpinner echo />
				) : (
					<>
						<div className={classNames('report-controls-overlay', getReportClasses())}>
							<ButtonGroup>
								{study.studyType === 'STRESS_ECHO' && (
									<Button
										className={classNames(reportState.builder === 'stress_ecg' ? 'report-builder-button active' : 'report-builder-button')}
										display={Display.Solid}
										colors={Colors.Secondary}
										onClick={() => onTabChange('stress_ecg')}
									>
										Stress ECG
									</Button>
								)}
								{pdfs.twelveLead !== '' && (
									<Button
										className={classNames(reportState.builder === 'twelve-lead' ? 'report-builder-button active' : 'report-builder-button')}
										display={Display.Solid}
										colors={Colors.Secondary}
										onClick={() => onTabChange('twelve-lead')}
									>
										12 Lead ECG
									</Button>
								)}
								<Button
									className={classNames(reportState.builder === 'referral' ? 'report-builder-button active' : 'report-builder-button')}
									display={Display.Solid}
									colors={Colors.Secondary}
									onClick={() => onTabChange('referral')}
								>
									Referral
								</Button>
								
								{(siteState.site.advancedReportBuilder && pdfs.preview !== '') && (
									<Button
										className={classNames(reportState.builder === 'preview' ? 'report-builder-button active' : 'report-builder-button')}
										display={Display.Solid}
										colors={Colors.Secondary}
										onClick={() => onTabChange('preview')}
									>
										Preview
									</Button>
								)}
								
								{!siteState.site.advancedReportBuilder && (
									<Button
										className={classNames(reportState.builder === 'basic' ? 'report-builder-button active' : 'report-builder-button')}
										display={Display.Solid}
										colors={Colors.Secondary}
										onClick={() => onTabChange('basic')}
									>
										Report
									</Button>
								)}
								
								{siteState.site.advancedReportBuilder && (
									<Button
										className={classNames(reportState.builder === 'advanced' ? 'report-builder-button active' : 'report-builder-button')}
										display={Display.Solid}
										colors={Colors.Secondary}
										disabled={!siteState.site.advancedReportBuilder}
										onClick={() => onTabChange('advanced')}
									>
										Report
									</Button>
								)}
								<Button
									className={classNames(reportState.builder === 'measurements' ? 'report-builder-button active' : 'report-builder-button')}
									display={Display.Solid}
									colors={Colors.Secondary}
									onClick={() => onTabChange('measurements')}
								>
									Measurements
								</Button>
							</ButtonGroup>
						</div>

						{reportState.builder === 'basic' && (
							<BasicReportBuilder
								study={study}
								site={siteState.site}
								basicReportState={basicReportState}
								reportingDoctors={doctorsState.reportingDoctors}
							/>
						)}
						{reportState.builder === 'advanced' && (
							<AdvancedReportBuilder
								study={study}
								site={siteState.site}
								observations={observationState}
								measurements={measurements}
								conclusionState={conclusionState}
								translations={translationState.translations}
								sonographers={doctorsState.sonographers}
								reportingDoctors={doctorsState.reportingDoctors}
								sentenceTemplates={translationState.sentenceTemplates}
								handleOptionSelect={handleOptionSelect}
								handleEditOptionOutput={handleEditOptionOutput}
								resetSection={resetSection}
								handleConclusionSelect={handleConclusionSelect}
								removeConclusion={removeConclusion}
								handleSentenceTemplateSelect={handleSentenceTemplateSelect}
								fetchPdf={fetchPreview}
							/>
						)}
						{reportState.builder === 'measurements' && (
							<MeasurementTile
								study={study}
								site={siteState.site}
								updateMeasurement={updateMeasurement}
								updateTranslations={updateTranslations}
								calculatedMeasurements={{ ...calculatedMeasurementsState }}
								reportMeasurementsState={{ ...reportMeasurementsState.reportMeasurementsObject }}
								updateReportMeasurementsState={updateReportMeasurementsState}
								refetchStudyMeasurements={refetchStudyMeasurements}
								measurementsLabelMap={translationState.measurementLabelMap}
							/>
						)}
						{reportState.builder === 'stress_ecg' && (
							<div className="stress-ecg-wrapper">
								<Button
									className="preview-button"
									onClick={() => window.open(pdfs.stressEcg)}
									display={Display.Solid}
									colors={Colors.Secondary}
								>
									Show in new tab
								</Button>

								{pdfs.stressEcg && (
									<embed src={`${pdfs.stressEcg}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" />
								)}
							</div>
						)}
						{reportState.builder === 'referral' && (
							<div className="stress-ecg-wrapper">
								<Button
									className="preview-button"
									onClick={() => window.open(pdfs.referral)}
									display={Display.Solid}
									colors={Colors.Secondary}
								>
									Show in new tab
								</Button>

								{pdfs.referral && (
									<embed src={`${pdfs.referral}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" />
								)}

							</div>
						)}
						
						{reportState.builder === 'preview' && (
							<div className="stress-ecg-wrapper">
								{pdfs.preview && (
									<embed src={`${pdfs.preview}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" />
								)}

							</div>
						)}
						
						{reportState.builder === 'twelve-lead' && (
							<div className="stress-ecg-wrapper">
								{pdfs.twelveLead && (
									<embed src={`${pdfs.twelveLead}#view=Fit&toolbar=1`} type="application/pdf" width="100%" height="100%" />
								)}

							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
});

export default withRouter(EchoStudyViewer); 

import { gql, useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Flex} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { StudyEntity } from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import ImageViewer from './ImageViewerComponents/ImageViewer';
import { MeasurementTable } from './MeasurementTable';
import NewAdvancedReportBuilder, { NewAdvancedReportBuilderMethods } from './NewAdvancedReportBuilder';
import NewBasicReportBuilder, { NewBasicReportBuilderMethods } from './NewBasicReportBuilder';
import NewReferralPreview from './NewReferralPreview';
import NewReportPreview from './NewReportPreview';
import NewStressECGPreview from './NewStressECGPreview';
import NewTwelveLeadPreview from './NewTwelveLeadPreview';
import RwmaPlotViewer from './RwmaPlotViewer';

interface NewEchoStudyViewerProps {
	studyId: string;
}

const CHECK_STUDY_FILE_EXISTS = gql`
	query CheckStudyType($args: [WhereExpressionGraph]) {
	  studyFileEntitys(where: $args) {
		id
	  }
  	}`;

const NewEchoStudyViewer = (props: NewEchoStudyViewerProps) => {
	const { studyId } = props;

	const { loading, data, error, refetch } = useQuery(StudyEntity.getMinStudy(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: studyId,
			}]
		},
		fetchPolicy: 'no-cache',
	});
	const { loading: referralLoading, data: referralData, error: referralError } = useQuery(CHECK_STUDY_FILE_EXISTS, {
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: studyId,
			}, 
			{
				path: 'FileType',
				comparison: 'equal',
				value: 'ECHO_REFERRAL',
			},]
		},
		fetchPolicy: 'no-cache',
	});
	
	const { loading: twelveLeadLoading, data: twelveLeadData, error: twelveLeadError } = useQuery(CHECK_STUDY_FILE_EXISTS, {
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: studyId,
			}, 
			{
				path: 'FileType',
				comparison: 'equal',
				value: 'HOLTER_ECG',
			},]
		},
		fetchPolicy: 'no-cache',
	});
	
	const { loading: stressECGLoading, data: stressECGLeadData, error: stressECGError } = useQuery(CHECK_STUDY_FILE_EXISTS, {
		variables: {
			args: [{
				path: 'studyId',
				comparison: 'equal',
				value: studyId,
			}, 
			{
				path: 'FileType',
				comparison: 'equal',
				value: 'STRESS_ECG_REPORT',
			},]
		},
		fetchPolicy: 'no-cache',
	});
	
	const [isImageViewerFullScreen, setIsImageViewerFullScreen] = useState(false);
	const [activePanel, setActivePanel] = useState('report');
	
	const [referral, setReferral] = useState(false);
	const [twelveLead, setTwelveLead] = useState(false);
	const [stressECGReport, setStressECGReport] = useState(false);
	
	const reportButtonRef = useRef<HTMLButtonElement>(null);
	const measurementButtonRef = useRef<HTMLButtonElement>(null);
	const previewButtonRef = useRef<HTMLButtonElement>(null);
	const referralButtonRef = useRef<HTMLButtonElement>(null);
	const twelveLeadButtonRef = useRef<HTMLButtonElement>(null);
	const stressECGReportButtonRef = useRef<HTMLButtonElement>(null);
	const RWMAButtonRef = useRef<HTMLButtonElement>(null);
	
	const [changes, setChanges] = useState(false)
	
	const advancedReporterRef = useRef<NewAdvancedReportBuilderMethods | null>(null);
	const basicReporterRef = useRef<NewBasicReportBuilderMethods | null>(null);
	
	const detectChanges = () => {
		setChanges(true);
	}
	
	useEffect(() => {
		setReferral(referralData?.studyFileEntitys?.length > 0);
		setTwelveLead(twelveLeadData?.studyFileEntitys?.length > 0);
		setStressECGReport(stressECGLeadData?.studyFileEntitys?.length > 0);
	}, [referralData, twelveLeadData, stressECGLeadData]);

	const { studyEntity: study } = data || {};
	
	if (loading || referralLoading || twelveLeadLoading || stressECGLoading) {
		return <CircularProgress isIndeterminate color='green.300' />
	}

	let rwmaAllowedStudyTypes = ['ECHO','STRESS_ECHO','DOBUTAMINE_STRESS_ECHO'];

	if(study.patient.site.rwmaPlotLabels) {
		rwmaAllowedStudyTypes = Object.keys(JSON.parse(study.patient.site.rwmaPlotLabels));
	} 
	const togglePanels = (newPanel: string) => {
		if (changes) {
			if (study.patient.site.advancedReportBuilder) {
				advancedReporterRef.current?.quietSave().then(() => {
					if (advancedReporterRef.current?.quietSave()) {
						if (newPanel === activePanel) {
							toggleFullScreen();
						} else {
							setActivePanel(newPanel);
							setIsImageViewerFullScreen(false);
						}
					}	
				})
			} else {
				basicReporterRef.current?.quietSave().then(() => {
					if (newPanel === activePanel) {
						toggleFullScreen();
					} else {
						setActivePanel(newPanel);
						setIsImageViewerFullScreen(false);
					}
				})
			}
			setChanges(false);
		} else {
			if (newPanel === activePanel) {
				toggleFullScreen();
			} else {
				setActivePanel(newPanel);
				setIsImageViewerFullScreen(false);
			}
		}
	};
	
	const toggleFullScreen = () => {
		setIsImageViewerFullScreen(prev => !prev);
	};
	
	const renderPanel = () => {
		if (isImageViewerFullScreen) {
			return <></>
		}
		
		if (activePanel === 'report') {
			if (study.patient.site.advancedReportBuilder) {
				return <NewAdvancedReportBuilder study={study} refetch={refetch} detectChanges={detectChanges} ref={advancedReporterRef}/>;
			} else {
				return <NewBasicReportBuilder study={study} refetch={refetch} detectChanges={detectChanges} ref={basicReporterRef} />;
			}
		} else if (activePanel === 'measurement') {
			return <MeasurementTable study={new StudyEntity(study)} refetch={refetch} />
		}
		else if (activePanel === 'preview') {
			return <NewReportPreview study={study} refetch={refetch} />
		}
		else if (activePanel === 'referral') {
			return <NewReferralPreview study={study} />
		}
		else if(activePanel === 'twelve-lead') {
			return <NewTwelveLeadPreview study={study} />
		}
		else if (activePanel === 'stress-ecg-report') {
			return <NewStressECGPreview study={study} />
		}
		else if (activePanel === 'rwma-plot') {
			return <RwmaPlotViewer study={study} refetch={refetch} ></RwmaPlotViewer>
		}
		else {
			return <>Loading...</>
		}
	}
	
	return (
		<Flex gridColumn="1 / -1" width='100vw' height='100vh' position='relative'>
			<Box flex='1' bg='#100f19' padding="20px" transition="width 0.3s" >
				<ImageViewer study={study} isImageViewerFullScreen={isImageViewerFullScreen} />
			</Box>
			<Box flex='1' display={isImageViewerFullScreen ? 'none' : ''} padding="20px" overflowY='auto' backgroundColor="#373151" color="#eeeeee">
				{renderPanel()}
			</Box>

			<Box
				transition="width 0.3s"
				position="absolute"
				left={isImageViewerFullScreen ? `calc(100% - (${reportButtonRef?.current?.offsetHeight}px /2))` : '50%'}
				transform="translate(-50%, -50%) rotate(90deg)"
				top="5%"
			>
				<Box
					display="flex"
					alignItems="center"
				>
					<Button
						ref={measurementButtonRef}
						onClick={() => togglePanels('measurement')}
						colorScheme={activePanel === 'measurement' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						Measurements
					</Button>

					<Button
						ref={reportButtonRef}
						onClick={() => togglePanels('report')}
						colorScheme={activePanel === 'report' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						Report
					</Button>

					{(study.report?.reportDataGenerated || (study.report?.reportStatus !== 'NOT_REPORTED' && study.report?.reportData)) && (
						<Button
							ref={previewButtonRef}
							onClick={() => togglePanels('preview')}
							colorScheme={activePanel === 'preview' ? 'plumPurple' : 'trueBlack'}
							marginRight="10px"
							left="50%"
						>
							Preview
						</Button>
					)}
					
					{referral && (
						<Button
							ref={referralButtonRef}
							onClick={() => togglePanels('referral')}
							colorScheme={activePanel === 'referral' ? 'plumPurple' : 'trueBlack'}
							marginRight="10px"
							left="50%"
						>
							Referral
						</Button>
					)}
					
					{twelveLead && (
						<Button
							ref={twelveLeadButtonRef}
							onClick={() => togglePanels('twelve-lead')}
							colorScheme={activePanel === 'twelve-lead' ? 'plumPurple' : 'trueBlack'}
							marginRight="10px"
							left="50%"
						>
							12 Lead ECG
						</Button>
					)}
					
					{stressECGReport && (
						<Button
							ref={stressECGReportButtonRef}
							onClick={() => togglePanels('stress-ecg-report')}
							colorScheme={activePanel === 'stress-ecg-report' ? 'plumPurple' : 'trueBlack'}
							marginRight="10px"
							left="50%"
						>
							Stress ECG
						</Button>
					)}
					{rwmaAllowedStudyTypes.includes(study.studyType) && study.patient.site.rwmaPlotLabels &&
					<Button
						ref={RWMAButtonRef}
						onClick={() => togglePanels('rwma-plot')}
						colorScheme={activePanel === 'rwma-plot' ? 'plumPurple' : 'trueBlack'}
						marginRight="10px"
						left="50%"
					>
						RWMA plot
					</Button>
					 }
				</Box>
			</Box>
		</Flex>
	);
}

export default NewEchoStudyViewer;
import { ILink } from 'Views/Components/Navigation/Navigation';
import { store } from 'Models/Store';
export function getFrontendNavLinks(): ILink[][] {
	return [
		[

			{label: "Home", path: '/patientdashboard', icon: "home", iconPos: 'icon-left'}

		],
		[

			{label: "Profile Page", path: `/profilepage/${store.userId ? store.userId : ''}`, icon: "person", iconPos: 'icon-left'},
			{label: "Session Page", path: '/sessionpage', icon: "clock", iconPos: 'icon-left', shouldDisplay: () => store.hasSessions},
			{label: "Patient Dashboard", path: '/patientdashboard', icon: "cards", iconPos: 'icon-left'},
			{
				label: 'Device Dashboard', path: '/devicedashboard', icon: 'usb', iconPos: 'icon-left', shouldDisplay: (): boolean => store.userGroups.map(ug => ug.name).includes('SuperAdmin'),
			},
			{
				label: 'Site Dashboard', path: '/sitedashboard', icon: 'location-map', iconPos: 'icon-left', shouldDisplay: (): boolean => store.userGroups.map(ug => ug.name).includes('SuperAdmin')  || store.userType === 'SITE_ADMIN',
			},
			{
				label: 'User Dashboard', path: '/userdashboard', icon: 'person-group', iconPos: 'icon-left', shouldDisplay: (): boolean => store.userGroups.map(ug => ug.name).includes('SuperAdmin') || store.userType === 'SITE_ADMIN',
			},

		],
		[

			{label: "Login", path: '/login', icon: "login", iconPos: 'icon-left', shouldDisplay: () => !store.loggedIn},
			{label: "Logout", path: '/logout', icon: "room", iconPos: 'icon-left', shouldDisplay: () => store.loggedIn}

		],
	];
} 

import {inject, observer} from 'mobx-react';
import React from 'react';
import Tabs from 'Views/Components/Tabs/Tabs';
import {withRouter, WithRouterProps} from "../../Util/withRouter";
import UserAccountRequestCrudTile from './UserSiteAccess/UserAccountRequestCrudTile';
import UserSiteAccessCrudTile from './UserSiteAccess/UserSiteAccessCrudTile';

const UserDashboardWrappingTileTile = (props: WithRouterProps) => {
	return (
		<div className="user-dashboard__container">
			<Tabs
				tabs={[
					{
						name: 'User Accounts',
						component: (<UserSiteAccessCrudTile/>),
						key: 'users',
					},
					{
						name: 'Account Requests',
						component: (<UserAccountRequestCrudTile/>),
						key: 'requests',
					},
				]}
				defaultTab={0}
				className={props.location.pathname.includes('create') ? 'hidden' : ''}
			/>
		</div>
	);
};

export default inject('store')(observer(withRouter(UserDashboardWrappingTileTile)));

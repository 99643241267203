import { useQuery } from '@apollo/client';
import { Alert, AlertIcon, CircularProgress, Code, Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router';
import NewCTStudyViewer from 'Views/Tiles/Study/NewCTStudyViewer';
import { StudyEntity } from '../../Models/Entities';
import CTStudyViewer from '../Tiles/Study/CTStudyViewer';
import NewEchoStudyViewer from '../Tiles/Study/NewEchoStudyViewer';
import NewHolterStudyViewer from '../Tiles/Study/NewHolterStudyViewer';

const NewStudyPage = () => {
	const params = useParams();

	const { loading, data, error } = useQuery(StudyEntity.getStudyType(), {
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: params.id,
			}]
		}
	});
	
	if (loading) {
		return <CircularProgress isIndeterminate color='green.300' />
	}
	
	if (error) {
		return (
			<Stack spacing={3}>
				<Alert status='error'>
					<AlertIcon />
					There was an error loading the study: 
					<Code>{error?.message}</Code>
				</Alert>
			</Stack>
		)
	}
	
	const renderStudyViewer = () => {
		const { studyEntity: study }: { studyEntity: StudyEntity } = data;
		
		if (study.studyType === 'HOLTERTWENTYFOURHR'
			|| study.studyType === 'HOLTERFORTYEIGHTHR'
			|| study.studyType === 'HOLTEREXTENDED'
			|| study.studyType === 'AMBULATORY_BP'
			|| study.studyType === 'EVENT_MONITOR'
			|| study.studyType === 'EXERCISE_STRESS_ECG') {
			return <NewHolterStudyViewer studyId={study.id}/>;
		} else if (study.studyType === 'ECHO'
			|| study.studyType === 'STRESS_ECHO'
			|| study.studyType === 'DOBUTAMINE_STRESS_ECHO'
			|| study.studyType === 'TRANSOESOPHAGEAL_ECHO'
			|| study.studyType === 'PAEDIATRIC_ECHO') {
			return <NewEchoStudyViewer studyId={study.id} />
		} else if (study.studyType === 'CTCA'
			|| study.studyType === 'CTCS') {
			return <NewCTStudyViewer studyId={study.id} />
		} else {
			return <div>Study doesn't exist</div>
		}
	}
	
	return renderStudyViewer();

}

export default NewStudyPage;
import {gql} from '@apollo/client';
import * as React from 'react';
import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions, getModelName, getAttributes,
} from 'Util/EntityUtils';
import { VisitorsStudyFileEntity } from 'Models/Security/Acl/VisitorsStudyFileEntity';
import { SuperAdminStudyFileEntity } from 'Models/Security/Acl/SuperAdminStudyFileEntity';
import { UserStudyFileEntity } from 'Models/Security/Acl/UserStudyFileEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { useObserver } from 'mobx-react';
import { VisitorsReadEntity } from 'Models/Security/Acl/VisitorsReadEntity';
import {lowerCaseFirst} from '../../Util/StringUtils';
import * as Enums from '../Enums';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import { StudyFilePreview } from '../../Views/Tiles/StudyFile/StudyFilePreview';
import { CRUD } from '../CRUDOptions';

export interface IStudyFileEntityAttributes extends IModelAttributes {
	fileType: Enums.studyFileType;
	archiveId: string;
	status: Enums.fileStatus;
	studyFileId: string;
	studyFile: Blob;
	jobid: string;
	instanceId: string;
	thumbnail: string;
	studyId?: string;
	study?: Models.StudyEntity | Models.IStudyEntityAttributes;
	sessionId?: string;
}

const StatusDisplay = ({ value }: {value: string}) => useObserver(() => <div>{value}</div>);

@entity('StudyFileEntity', 'Study File')
export default class StudyFileEntity extends Model implements IStudyFileEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsStudyFileEntity(),
		new SuperAdminStudyFileEntity(),
		new UserStudyFileEntity(),
		new VisitorsReadEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];

	/**
	 * Study File Type
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'File Type',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.studyFileTypeOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.studyFileTypeOptions),
		displayFunction: (attribute: Enums.studyFileType) => Enums.studyFileTypeOptions[attribute],
	})
	public fileType: Enums.studyFileType;

	/**
	 * Archive Id
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Archive Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public archiveId: string;

	/**
	 * The S3 status of the file
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => AttrUtils.standardiseEnum(attr, Enums.fileStatusOptions),
		enumResolveFunction: makeEnumFetchFunction(Enums.fileStatusOptions),
		displayFunction: (attribute: Enums.fileStatus) => <StatusDisplay value={attribute} />,
	})
	public status: Enums.fileStatus;

	/**
	 * Study File
	 */
	@observable
	@attribute({ file: 'studyFile' })
	@CRUD({
		name: 'Study File',
		displayType: 'file',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		fileAttribute: 'studyFile',
		displayFunction: (attr, that) => (attr
			? <StudyFilePreview url={attr} model={that as any} />
			: 'No File Attached'),
	})
	public studyFileId: string;
	
	@observable
	public studyFile: Blob;

	/**
	 * Job Id used for restoring
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'JobId',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public jobid: string;

	/**
	 * Instance Id
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Instance Id',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public instanceId: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Study',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.StudyEntity,
	})
	public studyId?: string;

	@observable
	@attribute({ isReference: true })
	public study: Models.StudyEntity;

	@observable
	public thumbnail: string;
	
	@observable
	@attribute()
	public sessionId: string;

	constructor(attributes?: Partial<IStudyFileEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IStudyFileEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.fileType !== undefined) {
				this.fileType = attributes.fileType;
			}
			if (attributes.sessionId !== undefined) {
				this.sessionId = attributes.sessionId;
			}
			if (attributes.archiveId !== undefined) {
				this.archiveId = attributes.archiveId;
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.studyFile !== undefined) {
				this.studyFile = attributes.studyFile;
			}
			if (attributes.studyFileId !== undefined) {
				this.studyFileId = attributes.studyFileId;
			}
			if (attributes.jobid !== undefined) {
				this.jobid = attributes.jobid;
			}
			if (attributes.instanceId !== undefined) {
				this.instanceId = attributes.instanceId;
			}
			if (attributes.studyId !== undefined) {
				this.studyId = attributes.studyId;
			}
			if (attributes.thumbnail !== undefined) {
				this.thumbnail = attributes.thumbnail;
			}
			if (attributes.study !== undefined) {
				if (attributes.study === null) {
					this.study = attributes.study;
				} else if (attributes.study instanceof Models.StudyEntity) {
					this.study = attributes.study;
					this.studyId = attributes.study.id;
				} else {
					this.study = new Models.StudyEntity(attributes.study);
					this.studyId = this.study.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		study {
			${Models.StudyEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		return this.id;
	}

	public static getFetchStudyFile() {
		const modelName = lowerCaseFirst(getModelName(StudyFileEntity));

		return gql`
			query ${modelName}s ($args:[WhereExpressionGraph]) {
				${modelName}s (where: $args) {
					studyFileId
				}
			}`;
	}
}
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(StudyFileEntity.prototype, 'created');
CRUD(modifiedAttr)(StudyFileEntity.prototype, 'modified');

import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import SharedStudyBreakGlass from 'Views/Tiles/Modals/SharedStudyBreakGlass';
import publicIp from 'public-ip';
import { AuditEntity, ShareLinkEntity, StudyEntity } from '../../Models/Entities';
import { store } from '../../Models/Store';
import SecuredPage from '../Components/Security/SecuredPage';
import Navigation, { Orientation } from '../Components/Navigation/Navigation';
import { getFrontendNavLinks } from '../FrontendNavLinks';
import HolterStudyViewer from '../Tiles/Study/HolterStudyViewer';
import alert from '../../Util/ToastifyUtils';
import EchoStudyViewer from '../Tiles/Study/EchoStudyViewer';
import CustomSpinner from "../Components/Spinner/CustomSpinner";
import { useParams } from 'react-router';
import {useNavigate} from "react-router-dom";
import CTStudyViewer from 'Views/Tiles/Study/CTStudyViewer';

const StudyPage = observer(() => {
	const studyStore = useLocalStore(() => ({
		study: new StudyEntity(),
		loading: true,
		acceptedTermsAndConditions: false,
	}));
	
	const params = useParams(); 
	const navigate = useNavigate();

	const setAccepted = () => {
		runInAction(() => studyStore.acceptedTermsAndConditions = true); 
	};

	const fetchSharedStudy = () => {
		Axios.get(`${SERVER_URL}/api/entity/ShareLinkEntity/${params.shareKey}`)
			.then(data => {
				const shareLink = new ShareLinkEntity(data.data);
				if (moment().isBefore(moment(shareLink.expiryDate))) {
					if (shareLink.studyId === params.id) {
						store.apolloClient.query({
							query: StudyEntity.getFetchStudy(),
							fetchPolicy: 'network-only',
							variables: {
								args: [{
									path: 'id',
									comparison: 'equal',
									value: params.id,
								}],
							},
						}).then(async res => {
							runInAction(() => {
								studyStore.study = new StudyEntity(res.data.studyEntity);
								studyStore.loading = false;
								studyStore.study.patient.siteId = res.data.studyEntity.patient.siteId;
							});

							const ipAddress = await publicIp.v4();
							
							const auditEntity = new AuditEntity();
							auditEntity.eventType = 'Share link visited';
							auditEntity.eventDetail = `Visitor viewed study with id: ${studyStore.study.id} via share link with id: ${shareLink.id} email: ${shareLink.emailAddress} from ip: ${ipAddress}`;
							auditEntity.save();

							shareLink.lastVisited = new Date();
							shareLink.countVisited = shareLink.countVisited ? shareLink.countVisited + 1 : 1;
							shareLink.save();
						}).catch(err => console.log(err));
					} else {
						navigate(`/login`);
					}
				} else {
					navigate(`/login`);
				}
			})
			.catch(error => {
				console.log('error', error);
				navigate(`/login`);
			});
	};
	
	useEffect(() => {
		if (!store.loggedIn) {
			if (params.shareKey) {
				Axios.get(`${SERVER_URL}/api/entity/ShareLinkEntity/${params.shareKey}`).then(
					res => {
						if (res.data != null) {
							if (moment().isBefore(moment(res.data.expiryDate)) && !res.data.revoked) {
								store.modal.show('Terms and Conditions', 
									<SharedStudyBreakGlass setAccepted={setAccepted} />);
							} else {
								alert('Study share link has expired.', 'warning');
								navigate(`/login`);
							}
						}
					},
				);
			}
		} else {
			store.apolloClient.query({
				query: StudyEntity.getFetchStudy(),
				fetchPolicy: 'network-only',
				variables: {
					args: [{
						path: 'id',
						comparison: 'equal',
						value: params.id,
					}],
				},
			}).then(res => {
				runInAction(() => {
					studyStore.study = new StudyEntity(res.data.studyEntity);
					studyStore.loading = false;
				});
			}).catch(err => console.log(err));
		}
	}, []);

	useEffect(() => {
		if (studyStore.acceptedTermsAndConditions) {
			fetchSharedStudy();
		}
	}, [studyStore.acceptedTermsAndConditions]);

	const renderStudyViewer = () => {
		if (studyStore.study.studyType === 'HOLTERTWENTYFOURHR'
			|| studyStore.study.studyType === 'HOLTERFORTYEIGHTHR'
			|| studyStore.study.studyType === 'HOLTEREXTENDED'
			|| studyStore.study.studyType === 'AMBULATORY_BP'
			|| studyStore.study.studyType === 'EVENT_MONITOR'
			|| studyStore.study.studyType === 'EXERCISE_STRESS_ECG') {
			return <HolterStudyViewer study={studyStore.study}/>;
		} else if (studyStore.study.studyType === 'ECHO'
			|| studyStore.study.studyType === 'STRESS_ECHO'
			|| studyStore.study.studyType === 'DOBUTAMINE_STRESS_ECHO'
			|| studyStore.study.studyType === 'TRANSOESOPHAGEAL_ECHO'
			|| studyStore.study.studyType === 'PAEDIATRIC_ECHO') {
			return <EchoStudyViewer study={studyStore.study} />
		} else if (studyStore.study.studyType === 'CTCA'
		|| studyStore.study.studyType === 'CTCS') {
		return <CTStudyViewer study={studyStore.study} />
		} else {
			return <div>Study doesn't exist</div>
		}
	}

	const navigationLinks = getFrontendNavLinks();
	
	if (!studyStore.loading) {
		return (
			<SecuredPage>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
				/>
				<div className="body-content">
					{renderStudyViewer()}
				</div>
			</SecuredPage>
		);
	}

	return (
		<div className="study-loading">
			<CustomSpinner />
		</div>
	);
});

export default StudyPage;

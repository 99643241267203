import * as React from 'react';
import { inject, observer } from 'mobx-react';
import EntityCRUD from 'Views/Components/CRUD/EntityCRUD';
import * as Models from 'Models/Entities';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { SERVER_URL } from 'Constants';
import Tabs from 'Views/Components/Tabs/Tabs';
import { withRouter, WithRouterProps }   from "../../Util/withRouter";
import UserSiteRequestCrudTile from './UserSiteAccess/UserSiteRequestCrudTile';
import { store } from 'Models/Store';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';

const SiteDashboardWrappingTileTile = (props: WithRouterProps) => {
	const { navigate, params, location } = props;
	const additionalTableActionsMore = (): Array<ICollectionItemActionProps<Models.SiteEntity>> => {
		const additionalTableActions = [] as ICollectionItemActionProps<Models.SiteEntity>[];

		additionalTableActions.push({
			action: item => {
				navigate(`/sitedashboard/edit/${item.id}`)
			},
			label: 'Edit',
			showIcon: true,
			icon: 'edit',
			iconPos: 'icon-right',
			buttonClass: 'btn--text',
		});

		additionalTableActions.push({
			action: item => {
				navigate(`/site/${item.id}`);
			},
			label: 'View',
			showIcon: true,
			icon: 'chevron-right',
			iconPos: 'icon-right',
			buttonClass: 'btn--text',
		});

		return additionalTableActions;
	};

	const extraConditions = () => {
		let conditions = [] as IWhereCondition<Models.SiteEntity>[];

		console.log(store.userGroups)
		console.log(store.userId)

		if (store.userGroups.map(ug => ug.name).includes('User')) {
			conditions.push(
				{
					comparison: 'equal',
					path: 'userss.userId',
					value: store.userId,
				} as IWhereCondition<Models.SiteEntity>,
			)
		} else {
			return undefined;
		}

		return conditions;
	}

	const contents = (
		<div className="site-dashboard__container">
			<Tabs
				tabs={[
					{
						name: `${store.userGroups.map(ug => ug.name).includes('SuperAdmin') ? "All" : "Your"} Sites`,
						component: (
							<EntityCRUD
								modelType={Models.SiteEntity}
								additionalTableActions={additionalTableActionsMore()}
								// extraConditions={extraConditions()}
								removeEditButton
								removeDeleteButton
								removeViewAction
								removeCreatedFilter
								removeModifiedFilter
								removeGoButton
								removeApplyFilter
								dateFormat="d/m/Y"
								altFormat="d/m/Y"
							/>),
						key: 'sites',
					},
					{
						name: 'Site Requests',
						component: (
							<UserSiteRequestCrudTile />),
						key: 'requests',
					},
				]}
				defaultTab={0}
				className={props.location.pathname.includes('create') || props.location.pathname.includes('edit') ? 'hidden' : ''}
			/>
		</div>
	);

	return contents;
};

export default inject('store')(observer(withRouter(SiteDashboardWrappingTileTile)));

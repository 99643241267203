import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {runInAction, toJS} from 'mobx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {useNavigate} from 'react-router-dom';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import classNames from 'classnames';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import {
	DoctorEntity, SiteEntity, StudyEntity, UserEntity,
} from 'Models/Entities';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import alert from 'Util/ToastifyUtils';
import { SERVER_URL } from 'Constants';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import Axios from 'axios';
import { store } from '../../../Models/Store';
import axios from 'axios';

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
} from '@chakra-ui/react';
import {NumberTextField} from '../../Components/NumberTextBox/NumberTextBox';

interface AdvancedReportBuilderProps {
	study: StudyEntity;
	site: SiteEntity;
	measurements: any;
	observations: any;
	conclusionState: any;
	translations: any;
	sentenceTemplates: any;
	sonographers: string[];
	reportingDoctors: DoctorEntity[];
	handleOptionSelect: (section: string, element: string, option: string) => void;
	handleEditOptionOutput: (section: string, element: string, newVal: string) => void;
	resetSection: (section: string) => void;
	handleConclusionSelect: (section: string, element: string) => void;
	removeConclusion: (conclusion: IConclusion) => void;
	handleSentenceTemplateSelect: (severity: string) => void;
	fetchPdf: () => void;
}

interface Option {
	display: string;
	sentence: string;
	conclusion: string;
}

interface IConclusion {
	sentence: string;
	id: string;
}

const AdvancedReportBuilder = observer((props: AdvancedReportBuilderProps) => {
	const {
		study,
		site,
		observations,
		conclusionState,
		translations,
		sonographers,
		reportingDoctors,
		sentenceTemplates,
		handleOptionSelect,
		handleEditOptionOutput,
		handleConclusionSelect,
		removeConclusion,
		handleSentenceTemplateSelect,
		fetchPdf
	} = props;

	const confirmationState = useLocalStore(() => ({
		confirmed: study.report?.reportStatus === 'CONFIRMED',
		modalCheck: '',
		userDetails: new UserEntity(),
	}));

	// Returns an array with all of the sentence template for each severity of a respective section, and element 
	const getSentenceTemplateOptions = (section: string, element: string): any => {
		const ops: string[] = new Array();

		Object.keys(sentenceTemplates).forEach(type => {
			// Try catch here is used in case the section / element is not defined in the translation file 
			try {
				const { display } = sentenceTemplates[type][section][element][0];
				if (!translations[section][element].some((option: Option) => option.display === display)) {
					ops.push(sentenceTemplates[type][section][element][0].display);
				}
			} catch (e) {
				// Do nothing, since nothing needs to be included 
			}
		});

		return ops;
	};

	// Gets all of the severities from the json file, appends clear as well 
	const getSentenceTemplateSeverities = (): any => {
		const severities: string[] = new Array();
		Object.keys(sentenceTemplates).forEach((type: string) => {
			try {
				const prettyName: string = type.charAt(0).toUpperCase() + type.slice(1);
				severities.push(prettyName);
			} catch (e) {
				console.error(e);
			}
		});
		// For some reason, after an option is selected, 'Value' gets appended, remove it if it is present 
		if (severities.includes('Value')) {
			severities.pop();
		}
		severities.push('Clear');
		return severities;
	};

	const getReportTemplates = (): any => {
		const templates: string[] = new Array();
		templates.push('Default');
		Object.keys(site.reportTemplates).forEach((template: string) => {
			templates.push(site.reportTemplates[template].templateName);
		});
		if (templates.length > (site.reportTemplates.length + 1)) {
			templates.pop();
		}
		return templates;
	};

	let hasEditAccess = false;

	if (store.userSiteAccess.filter(usa => usa.siteId === site.id).length > 0) {
		hasEditAccess = ((store.userType === 'CARDIAC_SCIENTIST' && study.report?.reportStatus !== 'CONFIRMED') || store.userType === 'REPORTING_DOCTOR')
			&& (store.userSiteAccess.filter(usa => usa.siteId === site.id)[0].accessLevel === 'FULL_ACCESS');
	}

	const loadState = useLocalStore(() => ({ isLoading: true }));

	const toggleStudyUrgency = (studyEntity: StudyEntity): void => {
		store.modal.show('Confirmation',
			<div className="confirmation__modal">
				<h4>{`Do you want to set the study urgency to ${studyEntity.urgent ? 'Routine' : 'Urgent'}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'confirm' }}
						onClick={(): void => runInAction(() => {
							studyEntity.toggleUrgent();
							studyEntity.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={(): void => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const setSelectedReportTemplate = (template: string, study: StudyEntity): void => {
		// Sometimes the template duplicates itself, remove the duplicate if this is the case 
		if (template === '') return;
		const duplicate = template.substring(Math.floor(template.length / 2), template.length);
		if (template.substring(0, Math.floor(template.length / 2)).match(duplicate)) {
			template = duplicate;
		}
		if (getReportTemplates().includes(template)) {
			runInAction(() => {
				study.setSelectedReportTemplate(template);
				study.save();
			});
		}
	};

	const reorder = (list: IConclusion[], startIndex: number, endIndex: number): IConclusion[] => {
		const [removed] = list.splice(startIndex, 1);
		list.splice(endIndex, 0, removed);

		return list;
	};

	const onDragEnd = (result: any): void => {
		// dropped outside the list 
		if (!result.destination) {
			return;
		}

		const items = reorder(
			conclusionState.conclusions,
			result.source.index,
			result.destination.index,
		);

		runInAction(() => {
			conclusionState.conclusions = items;
		});
	};

	const addEmptyConclusion = () => {
		const index = conclusionState.conclusions.length + 1;
		const newConclusion = { sentence: '', id: index };

		runInAction(() => {
			const newConclusions = [...conclusionState.conclusions];
			newConclusions.push(newConclusion);
			conclusionState.conclusions = newConclusions;
		});
	};

	const updateConclusions = (value: string, index: number): void => {
		runInAction(() => {
			const newConclusions = [...conclusionState.conclusions];
			newConclusions[index].sentence = value;
			conclusionState.conclusions = newConclusions;
		});
	};

	const retrieveUser = () => {
		store.apolloClient.query({
			query: UserEntity.getFetchSingleQueryProfilePage(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: store.userId,
				}],
			},
		}).then(res => {
			runInAction(() => {
				confirmationState.userDetails = new UserEntity(res.data.userEntity);
			});
		}).catch(error => {
			console.error(error);
		});
	};

	const getLoggedInName = async () => {
		const user = store.getUser;
		
		if (user?.userType === 'CARDIAC_SCIENTIST' && store.userSiteAccess.filter(usa => usa.siteId === site.id)[0].accessLevel === 'FULL_ACCESS') {
			await axios.get(`${SERVER_URL}/api/entity/UserEntity/${user?.id}`)
				.then(res => {
					if (res.data !== '') {
						if (observations.demographic.sonographer === '' || observations.demographic.sonographer === 'N/A') {
							runInAction(() => {
								observations.demographic.sonographer = res.data.name;
							});
						}
					}
				}).catch(err => console.log(err));
		}
	}
	
	const setDefaultTemplate = () => {
		if (!study.selectedReportTemplate || study.selectedReportTemplate === 'Default') {
			let reportName: string | undefined = undefined;
			
			if (study.studyType === 'ECHO') {
				reportName = site.reportTemplates.find(r => r.defaultEchoTemplate)?.templateName;
			}
			
			if (study.studyType === 'STRESS_ECHO') {
				reportName = site.reportTemplates.find(r => r.defaultStressTemplate)?.templateName;
			}

			if (study.studyType === 'DOBUTAMINE_STRESS_ECHO') {
				reportName = site.reportTemplates.find(r => r.defaultStressTemplate)?.templateName;
			}
			
			if (study.studyType === 'TRANSOESOPHAGEAL_ECHO') {
				reportName = site.reportTemplates.find(r => r.defaultTransTemplate)?.templateName;
			}

			if (study.studyType === 'CTCA') {
				reportName = site.reportTemplates.find(r => r.defaultCtcaTemplate)?.templateName;
			}
			
			if (study.studyType === 'CTCS') {
				reportName = site.reportTemplates.find(r => r.defaultCtcsTemplate)?.templateName;
			}

			if (study.studyType === 'PAEDIATRIC_ECHO') {
				reportName = site.reportTemplates.find(r => r.defaultEchoTemplate)?.templateName;
			}
			
			runInAction(() => {
				study.setSelectedReportTemplate(reportName ? reportName : 'Default');
				study.save();
			});
		}
	}

	useEffect(() => {
		retrieveUser();
		setDefaultTemplate();
		getLoggedInName();
		runInAction(() => {
			loadState.isLoading = false;
		});
	}, []);
	
	const navigate = useNavigate();

	const saveReport = async (update: boolean): Promise<void> => {
		if (study.report) { study.report.reportDataGenerated = JSON.stringify({ observation: observations, conclusion: conclusionState }); }
		if (observations.demographic.sonographer) { study.sonographer = observations.demographic.sonographer; }
		if (observations.demographic.clinicalDetails) { study.clinicalDetails = observations.demographic.clinicalDetails; }
		if (observations.demographic.reportingDoctor) {study.assignedDoctor = observations.demographic.reportingDoctor; }
		
		runInAction(() => {
			if (observations.demographic.referringDoctorPrefix || observations.demographic.referringDoctorFirstName || observations.demographic.referringDoctorLastName) {
				const referringDoctor = study.doctorss.filter(d => d.doctorType === 'REFERRING');
				
				if (referringDoctor.length > 0) {
					referringDoctor[0].prefix = observations.demographic.referringDoctorPrefix;
					referringDoctor[0].firstName = observations.demographic.referringDoctorFirstName;
					referringDoctor[0].lastName = observations.demographic.referringDoctorLastName;
				} else {
					const newReferringDoctor = new DoctorEntity({
						prefix: observations.demographic.referringDoctorPrefix,
						firstName: observations.demographic.referringDoctorFirstName,
						lastName: observations.demographic.referringDoctorLastName,
						doctorType: 'REFERRING',
					});
					study.doctorss.push(newReferringDoctor);
				}
			}
		});
		if (site.advancedReportBuilder) {
			let newReport = '';
			Object.keys(observations).forEach(key => {
				if (observations[key].sentence !== '' && observations[key].sentence !== null && observations[key].sentence !== undefined) {
					newReport += `${key}: ${observations[key].sentence}\n`;
				}
			});
			runInAction(() => {
				if (study.report) {
					study.report.reportData = newReport;
				}
			});
		}

		if (confirmationState.confirmed && !observations.demographic.reportingDoctor) {
			alert('Must set a reporting doctor', 'error');
			return;
		}

		runInAction(() =>  {
			if (study.report) {
				if (confirmationState.confirmed) {
					study.report.reportStatus = 'CONFIRMED';

					const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING');
					
					if (reportingDoctor.length === 0 && observations.demographic.reportingDoctor) {
						const reportingDoctorFound = reportingDoctors.filter(d => `${d.prefix} ${d.firstName} ${d.lastName}` === observations.demographic.reportingDoctor);
				
						if (reportingDoctorFound.length > 0) {
							study.doctorss.push(reportingDoctorFound[0]);
						}
					} else if (reportingDoctor.length > 0 && observations.demographic.reportingDoctor) {
						
						const reportingDoctorFound = reportingDoctors.filter(d => `${d.prefix} ${d.firstName} ${d.lastName}` === observations.demographic.reportingDoctor);
						
						if (reportingDoctorFound.length > 0) {
							const oldDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING' && d.id !== reportingDoctorFound[0].id);
							
							oldDoctor.forEach(d => {
								d.delete();
							});
							study.doctorss = [...study.doctorss.filter(d => !oldDoctor.map(od => od.id).includes(d.id))];
							study.doctorss.push(reportingDoctorFound[0]);
						}
					}
				}
			}
		});

		study.save({ report: {}, doctorss: {} }).then(() => {
			Axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXT/${study.id}`).then(res => {
				runInAction(() => {
					if (study.report) {
						study.report.advancedreportstring = res.data;
					}
				});
				if (update) {
					runInAction(() => {
						if (study.report?.reportStatus === 'NOT_REPORTED') {
							study.report.reportStatus = 'PROVISIONAL';
						}
					});
					
					navigate('/patientdashboard')
				}
				study.patient.save();
				study.save({ report: {} });
			}).catch(e => {
				alert('Error updating report text', 'error');
			});

			alert(<p>Report Saved</p>, 'success');
		});

		if (study.report?.reportStatus === 'CONFIRMED') {
			// Send to device agent
			axios.get(`${SERVER_URL}/api/entity/StudyEntity/SendReport/${study.id}/${study.selectedReportTemplate}`);
		}
		
		fetchPdf();
	};

	const saveReportLogic = (update: boolean): void => {
		if (study.report?.reportStatus === 'CONFIRMED') {
			const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING');

			// Only have name =/ 
			if (`${reportingDoctor[0].firstName} ${reportingDoctor[0].lastName}` !== confirmationState.userDetails.name) {
				alert('You are not this studies reporting doctor. Only the assigned reporting doctor can make edits to a confirmed report.', 'error');
				return;
			}

			store.modal.show('Confirmation',
				<div className="confirmation__modal">
					<h4>This study is already confirmed</h4>
					<h5>Type CONFIRM to make changes to the report</h5>
					<TextField model={confirmationState} modelProperty="modalCheck" placeholder="Type CONFIRM" />
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
						<Button
							colors={Colors.Primary}
							display={Display.Solid}
							sizes={Sizes.Medium}
							buttonProps={{ id: 'confirm' }}
							onClick={(): void => runInAction(() => {
								if (confirmationState.modalCheck === 'CONFIRM') {
									saveReport(update).then(() => {
										runInAction(() => {
											confirmationState.modalCheck = '';
										});

										store.modal.hide();
									});
								} else {
									alert('Type CONFIRM to continue', 'error');
								}
							})}
						>
							Confirm
						</Button>
						<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={(): void => store.modal.hide()}>Cancel</Button>
					</ButtonGroup>
				</div>);
		} else {
			saveReport(update);
		}
	};

	if (loadState.isLoading) { return <></>; }

	return (
		<div className={classNames('advanced-report-builder', store.hasBackendAccess && 'top-bar')}>
			<div className="section">
				<TextArea 
					model={observations.demographic}
					modelProperty="clinicalDetails"
					placeholder="Clinical Details"
					label="Clinical Details"
					isDisabled={!hasEditAccess}
					className="clinicalDetails"
					textAreaProps={{ rows: 2 }} 
				/>
				<div className="demographic-stats">
					<NumberTextField
						model={observations.demographic}
						modelProperty="height"
						placeholder="cm"
						label="Height (cm)"
						className="measurement-field"
						isDisabled={!hasEditAccess}
						inputClassName="height"
					/>
					<NumberTextField
						model={observations.demographic}
						modelProperty="weight"
						placeholder="kg"
						label="Weight (kg)" 
						className="measurement-field"
						isDisabled={!hasEditAccess}
						inputClassName="weight" 
					/>
					<NumberTextField
						model={observations.demographic}
						modelProperty="BSA"
						placeholder="m2"
						label="BSA (m2)"
						className="measurement-field"
						isDisabled={!hasEditAccess}
						inputClassName="bsa"
					/>
					<TextField
						model={observations.demographic}
						modelProperty="HR"
						placeholder="bpm"
						label="HR (bpm)"
						className="measurement-field"
						isDisabled={!hasEditAccess}
						inputClassName="bpm"
					/>
					
					<div className="blood-pressure">
						<TextField
							model={observations.demographic}
							modelProperty="SBP"
							placeholder="BP"
							label="BP"
							className="measurement-field"
							isDisabled={!hasEditAccess}
							inputClassName="sbp"
						/>
						<span>/</span>
						<TextField
							model={observations.demographic}
							modelProperty="DBP"
							placeholder="BP"
							label=""
							className="measurement-field_DBP"
							isDisabled={!hasEditAccess}
							inputClassName="dbp"
						/>
					</div>
					
					
					<Combobox
						model={study.patient}
						modelProperty="gender"
						label="Gender"
						className="reportingDoctor"
						isDisabled={!hasEditAccess}
						options={[
							{ display: 'Female', value: 'FEMALE' },
							{ display: 'Male', value: 'MALE' },
							{ display: 'NA', value: 'NA' },
						]}
						onAfterChange={(event): void => {
							runInAction(() => {
								// @ts-ignore 
								study.patient.gender = event.currentTarget.textContent;
							});
						}}
					/>

				</div>
				<div className="stakeholders">
					<div className="referring_doctor">
						<TextField
							model={observations.demographic}
							modelProperty="referringDoctorPrefix"
							placeholder="Dr"
							className="prefix"
							isDisabled={!hasEditAccess}
						/>
						<TextField
							model={observations.demographic}
							modelProperty="referringDoctorFirstName"
							placeholder="First Name"
							label="Referring Doctor"
							className="firstName"
							isDisabled={!hasEditAccess}
						/>
						<TextField
							model={observations.demographic}
							modelProperty="referringDoctorLastName"
							placeholder="Last Name"
							className="lastName"
							isDisabled={!hasEditAccess}
						/>
					</div>
					<Combobox
						model={observations.demographic}
						modelProperty="sonographer"
						label="Sonographer"
						className="sonographer"
						isDisabled={!hasEditAccess}
						options={[...sonographers.map(s => ({ display: s, value: s }))]}
						onAfterChange={(event): void => {
							runInAction(() => {
								observations.demographic.sonographer = event.currentTarget.textContent || '';
							});
						}}
					/>
					<Combobox
						model={observations.demographic}
						modelProperty="reportingDoctor"
						label="Assigned Doctor"
						className="reportingDoctor"
						isDisabled={!hasEditAccess}
						options={[...reportingDoctors.map(d => ({ display: `${d.prefix} ${d.firstName} ${d.lastName}`, value: `${d.prefix} ${d.firstName} ${d.lastName}` }))]}
						onAfterChange={(event): void => {
							runInAction(() => {
								observations.demographic.reportingDoctor = event.currentTarget.textContent || '';
							});
						}}
					/>
				</div>
			</div>
			<div className="section">
				<h4>Templates</h4>
				<div className="elements">
					<div className="element">
						<Combobox
							model={sentenceTemplates}
							modelProperty="value"
							label="Sentence Templates"
							options={getSentenceTemplateSeverities().map((s: string) => ({ display: s, value: s }))}
							onAfterChange={(event): void => handleSentenceTemplateSelect(event.currentTarget.textContent?.toLowerCase() || 'normal')}
							isDisabled={!hasEditAccess}
						/>
					</div>
					<div className="element">
						<Combobox
							model={site.reportTemplates}
							modelProperty="value"
							label="Report Templates"
							placeholder={study.selectedReportTemplate}
							options={getReportTemplates().map((s: string) => ({ display: s, value: s }))}
							onAfterChange={(event): void => runInAction(() => {
								setSelectedReportTemplate(event.currentTarget.textContent || 'Default', study);
							})}
							isDisabled={!hasEditAccess}
						/>
					</div>
				</div>
			</div>
			{Object.keys(translations).map((section, index) => (
				<div className={`section ${section.replace(/\s/g, '').replace(/\//g, '')}`} key={`section-${section}-${index}`}>
					<Accordion allowToggle defaultIndex={(store.userType === 'CARDIAC_SCIENTIST' || study.report?.reportStatus === 'CONFIRMED') ? 0 : undefined}> 
						<AccordionItem  border={0} sx={{'.chakra-collapse': {overflow: 'visible !important'}}}>
							<AccordionButton>
								<Box as="span" flex='1' textAlign='left'>
									<h4>{section}</h4>
								</Box>
								<AccordionIcon />
							</AccordionButton>
							
							<AccordionPanel>
								<div className="elements">
									{Object.keys(translations[section]).map(element => (
										<div className={`element ${element.replace(/\s/g, '').replace(/\//g, '')}`} key={`element-${element}-${index}`}>
											<div className="conclusion-checkbox">
												<input
													type="checkbox"
													name="conclusion"
													checked={observations[section][element].conclusion}
													disabled={observations[section][element].value === '' || (!hasEditAccess)}
													onChange={(): void => handleConclusionSelect(section, element)}
												/>
											</div>
										
											<Combobox 
												model={observations[section][element]}
												modelProperty="value"
												label={element}
												options={[{ display: 'N/A', value: '' },
													...translations[section][element].map((option: Option) => ({ display: option.display, value: option.display })),
													// Need to append all of the sentence templates for that element so that they will show in the combobox when selected 
													...getSentenceTemplateOptions(section, element).map((d: string) => ({ display: d, value: d }))]}
												onAfterChange={(event): void => handleOptionSelect(section, element, event.currentTarget.textContent || 'N/A')}
												isDisabled={!hasEditAccess}
											/>
										</div>
									))}
								</div>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>

					<TextArea
						model={observations[section]}
						modelProperty="sentence"
						textAreaProps={{ rows: 3 }}
						isDisabled={!hasEditAccess}
						onAfterChange={e => {
							let sentence = e.target.value;
							let elementLabel = '';
							Object.keys(observations[section]).forEach(element => {
								if (element !== 'sentence') {
									if (e.target.value.includes(observations[section][element].output)) {
										sentence = sentence.replace(observations[section][element].output, '');
									} else {
										elementLabel = element;
									}
								}
							});
							if (sentence && elementLabel === '') {
								const sentenceIndexes: any = [];
								Object.keys(observations[section]).forEach(element => {
									if (element !== 'sentence') {
										const start = e.target.value.indexOf(observations[section][element].output);
										const end = e.target.value.indexOf(observations[section][element].output) + observations[section][element].output.length;
										if (end !== 0) {
											sentenceIndexes.push({ label: element, start, end });
										}
									}
								});
								if (sentenceIndexes[0] !== undefined) {
									if (sentenceIndexes[0].start !== 0) {
										elementLabel = sentenceIndexes[0].label;
										sentence += observations[section][elementLabel].output;
									} else if (sentenceIndexes[sentenceIndexes.length - 1].end < e.target.value.length) {
										elementLabel = sentenceIndexes[sentenceIndexes.length - 1].label;
										sentence = observations[section][elementLabel].output + sentence;
									} else {
										sentenceIndexes.forEach((indexObj: any, ind: number) => {
											if (ind + 1 <= sentenceIndexes.length - 1) {
												if (sentenceIndexes[ind + 1].start - sentenceIndexes[ind].end > 1) {
													elementLabel = sentenceIndexes[ind + 1].label;
													sentence += observations[section][elementLabel].output;
												}
											}
										});
									}
								}
								if (elementLabel === '') {
									// the structure has been changed too much for logic to be applied. Set all options to N/A for this section. 
									// resetSection(section); 
									return;
								}
							}
							if (elementLabel !== '') {
								handleEditOptionOutput(section, elementLabel, sentence);
							}
						}}
					/>
				</div>
			))}

			<div className={classNames('recommendations', { disabled: !hasEditAccess })}>
				<h4>Recommendations</h4>
				<TextArea
					model={observations.demographic}
					textAreaProps={{ rows: 3 }}
					modelProperty="recommendations"
					isReadOnly={!hasEditAccess}
				/>
			</div>

			<div className="conclusions">
				<div className="conclusions-header">
					<h4>Conclusions</h4>

					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						className="submit-button"
						onClick={() => addEmptyConclusion()}
					>
						Add
					</Button>
				</div>

				<ConclusionArea
					onDragEnd={onDragEnd}
					conclusions={conclusionState.conclusions}
					updateConclusions={updateConclusions}
					hasEditAccess={hasEditAccess}
					removeConclusion={removeConclusion}
				/>
			</div>

			<div className={classNames('ccRecipients', { disabled: !hasEditAccess })}>
				<h4>CC Recipients</h4>
				<TextArea
					model={observations.demographic}
					textAreaProps={{ rows: 3 }}
					modelProperty="ccRecipients"
					isReadOnly={!hasEditAccess}
				/>
			</div>
			
			<div className="update-controls">
				<div className="urgent-group">
					<p>Urgency:</p>
					{study.urgent ? (
						<Button disabled={!hasEditAccess} colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} onClick={(): void => runInAction(() => toggleStudyUrgency(study))}><span className="icon-left icon-clock">Urgent</span></Button>
					) : (
						<Button disabled={!hasEditAccess} colors={Colors.Grey} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} buttonProps={{ id: 'routine' }} onClick={(): void => runInAction(() => toggleStudyUrgency(study))}>Routine</Button>
					)}
				</div>
				{hasEditAccess && (
					<>
						{(store.userType === 'REPORTING_DOCTOR' && study.report?.reportStatus !== 'CONFIRMED') && (
							<div className="confirm-report">
								<input
									type="checkbox"
									name="confirm"
									onChange={(): void => runInAction(() => {
										confirmationState.confirmed = !confirmationState.confirmed;
									})}
									checked={confirmationState.confirmed}
								/>
								<label htmlFor="confirm">Confirm Report</label>
							</div>
						)}
						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => saveReportLogic(false)}
						>
							Save
						</Button>

						<Button
							display={Display.Solid}
							colors={Colors.Primary}
							onClick={() => saveReportLogic(true)}
						>
							Save & Exit
						</Button>
					</>
				)}
			</div>
		</div>
	);
});

export default AdvancedReportBuilder;

interface ConclusionAreaProps {
	onDragEnd: (result: any) => void;
	conclusions: IConclusion[];
	updateConclusions: (value: string, index: number) => void;
	hasEditAccess: boolean;
	removeConclusion: (conclusion: IConclusion) => void;
}


const ConclusionArea = observer((props: ConclusionAreaProps) => (
	// @ts-ignore
	<DragDropContext onDragEnd={props.onDragEnd}>
		<ObserveredDroppable conclusions={props.conclusions} updateConclusions={props.updateConclusions} hasEditAccess={props.hasEditAccess} removeConclusion={props.removeConclusion} />
	</DragDropContext>
));

interface ObserveredDroppableProps {
	conclusions: IConclusion[];
	updateConclusions: (value: string, index: number) => void;
	hasEditAccess: boolean;
	removeConclusion: (conclusion: IConclusion) => void;
}

const ObserveredDroppable = observer((props: ObserveredDroppableProps) => (
	// @ts-ignore
	<Droppable droppableId="droppable-area">
		{provided => (
			<div {...provided.droppableProps} ref={provided.innerRef}>
				{props.conclusions.map((conclusion, index) => (
					<ObserveredDraggable key={`draggable-${index}`} conclusions={props.conclusions} conclusion={conclusion} index={index} updateConclusions={props.updateConclusions} hasEditAccess={props.hasEditAccess} removeConclusion={props.removeConclusion} />
				))}
			</div>
		)}
	</Droppable>
));

interface ObservedDraggableProps {
	conclusions: IConclusion[];
	conclusion: IConclusion;
	index: number;
	updateConclusions: (value: string, index: number) => void;
	hasEditAccess: boolean;
	removeConclusion: (conclusion: IConclusion) => void;
}

const ObserveredDraggable = observer((props: ObservedDraggableProps) => (
	// @ts-ignore
	<Draggable draggableId={`draggableId-${props.index}`} index={props.index}>
		{provided => (
			<div ref={provided.innerRef} {...provided.draggableProps} className="conclusion">
				<Conclusion conclusion={props.conclusion} conclusions={props.conclusions} index={props.index} updateConclusions={props.updateConclusions} hasEditAccess={props.hasEditAccess} />
				{(store.userType === 'CARDIAC_SCIENTIST' || store.userType === 'REPORTING_DOCTOR') && (
					<div {...provided.dragHandleProps}>
						<div className="conclusion-draghandle" />
						<div className="conclusion-draghandle" />
						<div className="conclusion-draghandle" />
					</div>
				)}
				<Button
					colors={Colors.Error}
					display={Display.Text}
					sizes={Sizes.Medium}
					icon={{ icon: 'bin-delete', iconPos: 'icon-left' }}
					className="delete-conclusion"
					onClick={(): void => props.removeConclusion(props.conclusion)}
				/>
			</div>
		)}
	</Draggable>
));

interface ConclusionProps {
	conclusion: IConclusion;
	conclusions: IConclusion[];
	index: number;
	updateConclusions: (value: string, index: number) => void;
	hasEditAccess: boolean;
}

const Conclusion = observer((props: ConclusionProps) => (
	<input
		className="conclusion-value"
		type="text"
		onChange={(event): void => props.updateConclusions(event.target.value, props.index)}
		value={props.conclusion.sentence}
		disabled={!props.hasEditAccess}
	/>
)); 
